/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import SeeSubmissionModal from "../../../../../components/SeeSubmission/SeeSubmissionModal";
import ActionButtonForTables from "../../../../../components/Table/ActionButtonForTables";
import { CustomDatatableV2 } from "../../../styles";
import SubmissionResultIndicator from "../../../../../components/Table/SubmissionResultIndicator";
import { Search } from "react-feather";
import TableTitle from "../../../../../components/Table/TableTitle";
import { Download } from "react-feather";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../../components/Table/DateTooltip";

const Submissions = ({ submissions, name }) => {
  const [submissionInfo, setSubmissionInfo] = useState(null);
  const options = makeDefaultTableOptions(`${name}-submissions`);
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };

  const columns = [
    {
      name: "submissionId",
      label: "submission id",
      options: {
        download: false,
        print: false,
        display: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} displayYear shouldLocalize />
        ),
      },
    },
    {
      name: "teamMemberName",
      label: "Team Member Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "passed",
      label: "Passed",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            title="view"
            Icon={Search}
            handleClick={() => setSubmissionInfo({ id: tableMeta.rowData[0] })}
          />
        ),
      },
    },
  ];

  const tableData = submissions.map((submission) => ({
    submissionId: submission.id,
    date: new Date(submission.created).toISOString(),
    teamMemberName: submission.name,
    score: `${submission.score}/${submission.questions}`,
    passed: submission.passed ? "Yes" : "No",
  }));

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle Icon={Download} title="Submissions" />}
        columns={columns}
        options={options}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <SeeSubmissionModal
        submissionInfo={submissionInfo}
        setSubmissionInfo={setSubmissionInfo}
      />
    </>
  );
};

export default Submissions;

Submissions.propTypes = {
  submissions: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};
