/* eslint-disable */
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import PropTypes from "prop-types";
import HalfPanel from "../UI/HalfPanel";
import RequiredChip from "../UI/RequiredChip";
import AddModuleImage from "./AddModuleImage";
import BasicsForm from "./BasicsForm";

const Basics = ({
  details,
  setSnackbarText,
  mutate,
  isModulePending,
  isModuleLive,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStatePending,
}) => {
  return (
    <>
      <HalfPanel title="Edit Basic Information">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BasicsForm
            details={details}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            isModulePending={isModulePending}
            isModuleLive={isModuleLive}
            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
            isModuleConvertingNextStatePending={
              isModuleConvertingNextStatePending
            }
          />
        </MuiPickersUtilsProvider>
      </HalfPanel>
      <div
        style={{
          flex: 1,
          width: 1,
          maxWidth: 1,
          background: "#eeeeee",
          height: "inherit",
        }}
      />

      <HalfPanel
        title="Edit Module Image"
        RightComponent={() => (
          <RequiredChip
            completed={Boolean(details?.image)}
            title="Required"
            small
            requirementText="Every module must have an image attached"
          />
        )}
      >
        <AddModuleImage
          details={details}
          setSnackbarText={setSnackbarText}
          mutate={mutate}
        />
      </HalfPanel>
    </>
  );
};

export default Basics;

Basics.propTypes = {
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  isModifyLive: PropTypes.bool.isRequired,
  isModulePending: PropTypes.bool.isRequired,
  isModuleConvertingNextStateLive: PropTypes.bool.isRequired,
  isModuleConvertingNextStatePending: PropTypes.bool.isRequired,
};
