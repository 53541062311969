/* eslint-disable */
import React, { useState } from "react";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import useRetailReportsTeamMemberList from "../../../../../swr/hooks/RetailReports/useRetailReportsTeamMemberList";
import Loader from "../../vendorReports/Loader";
import MemberTable from "./MemberTable";

const TeamMembersReports = () => {
  const [filterDateString, setFilterDateString] = useState(null);

  const { isLoading, teamMembers, isError } = useRetailReportsTeamMemberList(
    filterDateString?.queryString || null
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorBlockWithPermissions errorObj={isError} />;
  }

  return (
    <MemberTable
      teamMembers={teamMembers}
      filterDateString={filterDateString}
      setFilterDateString={setFilterDateString}
      reportLoading={isLoading}
    />
  );
};

export default TeamMembersReports;
