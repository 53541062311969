/* eslint-disable */
import * as Yup from "yup";

export const generateUpdatedValuesObj = (details, fieldName, fieldValue) => {
  const newValues = {
    id: details.id,
    name: details.name || "",
    description: details.description || "",
    estimated_time: details.estimatedTime,
    language: details.language,
    due_date: details?.dueDate,
    start_date: details?.startDate,
    display_answers: details.displayAnswers,
    disclaimer: details.disclaimer,
    disclaimer_text: details.disclaimerText,
  };

  if (fieldName) {
    newValues[fieldName] = fieldValue;

    if (fieldName === "disclaimer_text" && fieldValue.length > 1) {
      newValues["disclaimer"] = true;
    }

    if (fieldName === "disclaimer_text" && fieldValue.length <= 1) {
      newValues["disclaimer"] = false;
      newValues["disclaimer_text"] = "";
    }

    if (fieldName == "includeDisclaimer") {
      newValues["disclaimer_text"] = "";
      newValues["disclaimer"] = false;
    }
  }

  return newValues;
};

export const getInitialValues = (details, initialStartDate, initialDueDate) => {
  return {
    name: details.name,
    description: details.description ? details.description : "",
    timeToComplete: details.estimatedTime ? details.estimatedTime : 5,
    lang: details.language ? details.language : "en-us",
    startDate: initialStartDate,
    dueDate: initialDueDate,
    includeDisclaimer:
      (details.disclaimer && details.disclaimerText?.length) > 1 ? "Yes" : "No",
    disclaimer: details.disclaimer ? details.disclaimerText : "",
    showAnswers: details.displayAnswers ? "Yes" : "No",
  };
};

export const validationSchema = Yup.object({
  name: Yup.string("Enter Module Name").min(2),
  description: Yup.string(),
  disclaimer: Yup.string()
    .min(2, "Disclaimer should be a minimum of 6 characters")
    .max(400, "Description can be at most 400 characters"),
  timeToComplete: Yup.number()
    .min(1, "Must be at least 1 minute to complete")
    .max(60, "Maximum of 60 minutes to complete")
    .required("Required"),
  includeDisclaimer: Yup.string(),
  startDate: Yup.date().required(),
  endDate: Yup.date(),
  showAnswers: Yup.string(),
  lang: Yup.string(),
});

export const getModuleBasicsErrorObj = (error) => {
  const response = error?.response;

  if (response?.data?.error?.title) {
    const detailArray = response?.data?.error?.context
      ? Object.entries(response?.data?.error?.context).map((val) => `${val[1]}`)
      : [""];

    return {
      title: response?.data?.error?.details,
      detail: detailArray,
    };
  }
  return {
    title: "Error updating this module",
    detail: ["Please try this action again."],
  };
};

export const CLEAN_FIELD_NAMES = {
  name: "name",
  description: "description",
  language: "language",
  estimated_time: "minutes to complete",
  display_answers: "show correct answers",
  disclaimer_text: "disclaimer",
  includeDisclaimer: "disclaimer",
};

export const TOOLTIP_STRINGS = {
  DESCRIPTION:
    "Use this section to give some context about the module. This will help app users to understand what this module is about. Ex. “This module covers everything you need to know about our store’s XYZ policy.” OR “With the Holiday Season approaching, learn about our newest product that’s hitting the shelves this week!”",
  START_DATE:
    "Select the date you want to deploy this module. You can select today’s date or a future date. If you select a future date, the module will deploy at 10:30 AM EST on the selected date.",
  END_DATE:
    "Including an end date for a module is optional, but it can be useful for automatically closing a module at a specific date. Once the end date has passed, users who haven't completed the module will no longer be able to access it.",
  DISCLAIMER:
    "Disclaimers are short notices presented to users, requiring acknowledgement through a tick box before attempting the module. They can be used to convey important information and ensure compliance.",
  SHOW_ANSWERS:
    "After submission, would you like to show team members which answers they got wrong?",
};
