/* eslint-disable */
import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Modal,
  Collapse,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { addDays } from "date-fns";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { Calendar, X } from "react-feather";
import styled from "styled-components/macro";
import { TextField } from "../../styles";
import EditDescriptionModal from "../UI/EditDescriptionModal";
import EditNameModal from "../UI/EditNameModal";
import InfoTooltip from "../UI/InfoTooltip";
import RequiredChip from "../UI/RequiredChip";
import API from "../../../../axios/instances/API";
import { apiFormatDate } from "../../../../storage/helpers";
import BasicsErrorModal from "./BasicsErrorModal";
import EditDisclaimerModal from "../UI/EditDisclaimerModal";
import {
  getModuleBasicsErrorObj,
  generateUpdatedValuesObj,
  getInitialValues,
  validationSchema,
  CLEAN_FIELD_NAMES,
  TOOLTIP_STRINGS,
} from "./utils";

const GreyCalendar = styled(Calendar)`
  margin-right: 4px;
  color: ${(props) => props.theme.palette.grey[500]};

  &:hover {
    cursor: pointer;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 320px;
  height: 100%;
  border-radius: 0px;
  padding: 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 320px;
  height: 100%;
  width: 100%;
`;

const SwitchContainer = styled.div`
  width: 50%;
  padding: 0px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0px;
  flex-direction: column;
`;

const SmallTextfield = styled(TextField)`
  width: 100%;
`;

const Label = styled.span`
  font-size: 12px;
  color: darkgrey;
  font-weight: 400;
  margin-left: 2px;
`;

const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 2px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  gap: 4px;
`;

const EditLiveButton = styled(Button)`
  padding: 2px;
  margin-left: auto;
  border-radius: 3px;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 18px;
  max-height: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #eeeeee;
  }

  span {
    color: #337ab7;
    font-weight: 600;
    font-size: 10px;
  }
`;

const BasicsForm = ({
  details,
  setSnackbarText,
  mutate,
  isModuleLive,
  isModulePending,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStatePending,
}) => {
  const initialStartDate = details.startDate
    ? new Date(details.startDate.replace(/-/g, "/"))
    : null;

  const initialDueDate =
    details && details.dueDate
      ? new Date(details.dueDate.replace(/-/g, "/"))
      : null;

  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
  const [errorAlert, setErrorAlert] = useState(null);

  const [dueDateOpenNum, setDueDateOpenNum] = useState(0);

  const initialValues = useMemo(
    () => getInitialValues(details, initialStartDate, initialDueDate),
    []
  );

  const handleFieldBlur = async (e, fieldName, resetForm) => {
    try {
      const newValue = e.target.value;
      const updatedObj = generateUpdatedValuesObj(details, fieldName, newValue);
      const { data } = await API.put("dashboard/quiz/details/", updatedObj);
      await mutate();

      resetForm({
        values: {
          name: data.name,
          description: data.description,
          estimated_time: data.estimated_time,
          dueDate: data?.due_date
            ? new Date(data.due_date.replace(/-/g, "/"))
            : null,
          startDate: data?.start_date
            ? new Date(data?.start_date.replace(/-/g, "/"))
            : null,
          lang: data.language,
          includeDisclaimer:
            (data.disclaimer && data.disclaimer_text?.length) > 1
              ? "Yes"
              : "No",
          disclaimer: data.disclaimer_text,
          showAnswers: data.display_answers ? "Yes" : "No",
        },
      });
      setSnackbarText(`Updated module ${CLEAN_FIELD_NAMES[fieldName]} success`);
    } catch (error) {
      resetForm({
        values: {
          name: details.name,
          description: details.description,
          estimated_time: details.estimated_time,
          dueDate: details?.dueDate
            ? new Date(details.dueDate.replace(/-/g, "/"))
            : null,
          startDate: details?.dueDate
            ? new Date(details.startDate.replace(/-/g, "/"))
            : null,
          lang: details.language,
          includeDisclaimer:
            (details.disclaimer && details.disclaimer_text?.length) > 1
              ? "Yes"
              : "No",
          disclaimer: details.disclaimer_text,
          showAnswers: details.display_answers ? "Yes" : "No",
        },
      });
      const errorObj = getModuleBasicsErrorObj(error);
      setErrorAlert(errorObj);
    }
  };

  const handleUpdateStartDate = async (date, values, setFieldValue) => {
    try {
      if (date === values.startDate) return null;
      const shouldUpdateDueDate = date >= values.dueDate;

      setFieldValue("startDate", date);
      if (shouldUpdateDueDate) {
        setFieldValue("dueDate", null);
      }

      const updateObj = generateUpdatedValuesObj(details);

      updateObj["start_date"] = apiFormatDate(date);

      if (shouldUpdateDueDate) {
        updateObj["due_date"] = null;
        setDueDateOpenNum(0);
      }

      await API.put("dashboard/quiz/details", updateObj);
      await mutate();
      setSnackbarText(
        `Updated start date ${
          shouldUpdateDueDate ? "and end date" : ""
        } success`
      );
    } catch (error) {
      const erroObj = getModuleBasicsErrorObj(error);
      setFieldValue(
        "startDate",
        new Date(details.startDate.replace(/-/g, "/"))
      );
      setErrorAlert(erroObj);
    }
  };

  const handleUpdateEndDate = async (date, silent = false) => {
    try {
      const updateObj = generateUpdatedValuesObj(details);
      updateObj["due_date"] = apiFormatDate(date);

      await API.put("dashboard/quiz/details", updateObj);
      await mutate();
      if (!silent) {
        setSnackbarText("Updated end date success");
      }
    } catch (error) {
      const errorObj = getModuleBasicsErrorObj(error);
      setErrorAlert(errorObj);
    }
  };

  const handleRemoveDueDate = async (setFieldValue) => {
    try {
      setFieldValue("dueDate", null);
      const updatedObj = generateUpdatedValuesObj(details, "due_date", null);
      await API.put("dashboard/quiz/details/", updatedObj);
      await mutate();
      setSnackbarText("Removed module end date warning");
    } catch (error) {
      const errorObj = getModuleBasicsErrorObj(error);
      setErrorAlert(errorObj);
    }
  };

  const isLiveOrPendingInFuture = Boolean(
    isModuleLive ||
      isModulePending ||
      isModuleConvertingNextStateLive ||
      isModuleConvertingNextStatePending
  );

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleBlur,
          resetForm,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form noValidate>
            <FormWrapper>
              <LabelWrapper>
                <Label>Module Name</Label>
                {(isModuleLive || isModulePending) && (
                  <EditLiveButton onClick={() => setNameModalOpen(true)}>
                    <span>Edit</span>
                  </EditLiveButton>
                )}
              </LabelWrapper>
              <TextField
                type="text"
                name="name"
                fullWidth
                color="secondary"
                disabled={isLiveOrPendingInFuture}
                value={values.name}
                onChange={handleChange}
                onBlur={(e) => {
                  if (details.name === values.name) return null;
                  handleFieldBlur(e, "name", resetForm);
                }}
                helperText={
                  values?.name?.length === 100 ? "**100 characters maximum" : ""
                }
                variant="outlined"
                size="small"
                mb={3}
                style={{ background: "white" }}
                inputProps={{
                  maxLength: 100,
                }}
                InputProps={{
                  endAdornment: (
                    <RequiredChip
                      completed={Boolean(details?.name)}
                      small
                      requirementText="Every module must have a name."
                    />
                  ),
                }}
              />
              <LabelWrapper>
                <Label>Module Description</Label>
                <InfoTooltip title={TOOLTIP_STRINGS.DESCRIPTION} />
                {(isModuleLive || isModulePending) && (
                  <EditLiveButton onClick={() => setDescriptionModalOpen(true)}>
                    <span>Edit</span>
                  </EditLiveButton>
                )}
              </LabelWrapper>
              <TextField
                multiline
                minRows={4}
                disabled={isLiveOrPendingInFuture}
                type="text"
                color="secondary"
                name="description"
                value={values.description}
                style={{
                  background: "white",
                }}
                fullWidth
                onChange={handleChange}
                onBlur={(e) => {
                  if (values.description === details.description) return null;
                  handleFieldBlur(e, "description", resetForm);
                }}
                helperText={
                  values?.description?.length === 300
                    ? "** 300 characters maximum"
                    : null
                }
                autoComplete="new-password"
                variant="outlined"
                size="small"
                mb={3}
                inputProps={{
                  maxLength: 300,
                }}
                InputProps={{
                  endAdornment: (
                    <RequiredChip
                      completed={Boolean(details.description)}
                      small
                      requirementText="Every module must have a description."
                    />
                  ),
                }}
              />
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <LabelWrapper>
                    <Label>Module Start Date</Label>
                    <InfoTooltip title={TOOLTIP_STRINGS.START_DATE} />
                  </LabelWrapper>
                  <DatePicker
                    size="small"
                    disableToolbar
                    autoOk
                    fullWidth
                    name="startDate"
                    color="secondary"
                    minDateMessage={" "}
                    disablePast
                    disabled={isLiveOrPendingInFuture}
                    inputVariant="outlined"
                    variant="inline"
                    format="yyyy-MM-dd"
                    value={values.startDate}
                    style={{ background: "white" }}
                    onChange={(date) => {
                      handleUpdateStartDate(date, values, setFieldValue);
                    }}
                    InputProps={{
                      endAdornment: (
                        <RequiredChip
                          completed={Boolean(details?.startDate)}
                          small
                          requirementText="Every module must have a start date."
                        />
                      ),
                      startAdornment: <GreyCalendar />,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <LabelWrapper>
                    <Label>Module End Date</Label>
                    <InfoTooltip title={TOOLTIP_STRINGS.END_DATE} />
                  </LabelWrapper>

                  <DatePicker
                    disablePast
                    disableToolbar
                    color="secondary"
                    inputVariant="outlined"
                    name="dueDate"
                    size="small"
                    autoOk
                    style={{ background: "white" }}
                    fullWidth
                    minDate={
                      isModuleLive || isModuleConvertingNextStateLive
                        ? addDays(new Date(), 1)
                        : addDays(values.startDate, 1)
                    }
                    variant="inline"
                    format="yyyy-MM-dd"
                    value={values.dueDate}
                    onChange={(date) => {
                      setFieldValue("dueDate", date);
                      handleUpdateEndDate(date, dueDateOpenNum === 0);
                      setDueDateOpenNum((p) => p + 1);
                    }}
                    InputProps={{
                      endAdornment: (
                        <>
                          {values.dueDate ? (
                            <X
                              style={{
                                color: "darkgrey",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveDueDate(setFieldValue);
                                setDueDateOpenNum(0);
                              }}
                            />
                          ) : null}
                        </>
                      ),
                      startAdornment: <GreyCalendar height={18} width={18} />,
                    }}
                  />
                </Grid>
              </Grid>
              <Box mt={4}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <LabelWrapper>
                        <Label>Language</Label>
                      </LabelWrapper>
                      <Select
                        name="lang"
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        onClick={(e) => {
                          // dont run if its the same //
                          if (e.target.value === 0) return;
                          handleFieldBlur(e, "language", resetForm);
                        }}
                        value={values.lang}
                        onChange={handleChange}
                        style={{ background: "white" }}
                        color="secondary"
                      >
                        <MenuItem value="en-us">English</MenuItem>
                        <MenuItem value="fr">French</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <LabelWrapper>
                      <Label>Minutes to complete</Label>
                    </LabelWrapper>
                    <TextField
                      name="timeToComplete"
                      type="number"
                      value={values.timeToComplete}
                      style={{ background: "white" }}
                      color="secondary"
                      fullWidth
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 1,
                        },
                      }}
                      error={Boolean(
                        touched.timeToComplete && errors.timeToComplete
                      )}
                      onChange={handleChange}
                      onBlur={(e) => {
                        if (
                          values.timeToComplete === details.estimatedTime ||
                          !values.timeToComplete
                        )
                          return null;
                        handleFieldBlur(e, "estimated_time", resetForm);
                      }}
                      helperText={
                        touched.timeToComplete && errors.timeToComplete
                      }
                      autoComplete="new-password"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <SwitchContainer>
                    <LabelWrapper>
                      <Label>Include a disclaimer?</Label>
                      <InfoTooltip title={TOOLTIP_STRINGS.DISCLAIMER} />
                    </LabelWrapper>
                    <SmallTextfield
                      size="small"
                      select
                      color="secondary"
                      disabled={isLiveOrPendingInFuture}
                      onBlur={handleBlur}
                      variant="outlined"
                      fullWidth
                      style={{ background: "white" }}
                      value={values.includeDisclaimer}
                      name="includeDisclaimer"
                      onChange={(e) => {
                        if (e.target.value === "Yes") {
                          return handleChange(e);
                        } else {
                          handleFieldBlur(e, "includeDisclaimer", resetForm);
                          handleChange(e);
                        }
                      }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </SmallTextfield>
                  </SwitchContainer>
                  <SwitchContainer>
                    <LabelWrapper>
                      <Label>Show incorrect answers?</Label>
                      <InfoTooltip title={TOOLTIP_STRINGS.SHOW_ANSWERS} />
                    </LabelWrapper>

                    <SmallTextfield
                      size="small"
                      select
                      color="secondary"
                      variant="outlined"
                      fullWidth
                      value={values.showAnswers}
                      onBlur={handleBlur}
                      name="showAnswers"
                      onChange={(e) => {
                        handleChange(e);
                        handleFieldBlur(e, "display_answers", resetForm);
                      }}
                      saved={details.displayAnswers}
                      style={{ background: "white" }}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </SmallTextfield>
                  </SwitchContainer>
                </Grid>
              </Box>
              <Collapse in={values.includeDisclaimer === "Yes"}>
                <Box mt={2}>
                  <TextField
                    mt={2}
                    multiline
                    minRows={4}
                    type="text"
                    name="disclaimer"
                    label="Disclaimer"
                    value={values.disclaimer}
                    fullWidth
                    disabled={isLiveOrPendingInFuture}
                    color="secondary"
                    error={Boolean(touched.disclaimer && errors.disclaimer)}
                    onChange={handleChange}
                    onBlur={async (e) => {
                      handleFieldBlur(e, "disclaimer_text", resetForm);
                    }}
                    helperText={touched.disclaimer && errors.disclaimer}
                    autoComplete="new-password"
                    variant="outlined"
                    size="small"
                    // mb={4}
                  />
                </Box>
                {(isModuleLive || isModulePending) && (
                  <>
                    <div style={{ height: 2 }} />
                    <EditLiveButton
                      style={{
                        width: "fit-content",
                        maxWidth: "fit-content",
                        minWidth: "fit-content",
                      }}
                      onClick={() => setDisclaimerModalOpen(true)}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        Edit Disclaimer
                      </span>
                    </EditLiveButton>
                  </>
                )}
              </Collapse>
            </FormWrapper>
            {(isModuleLive || isModulePending) && (
              <>
                <Modal
                  open={nameModalOpen}
                  onClose={() => setNameModalOpen(false)}
                >
                  <div>
                    <EditNameModal
                      details={details}
                      setSnackbarText={setSnackbarText}
                      handleClose={() => setNameModalOpen(false)}
                      mutate={mutate}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </Modal>
                <Modal
                  open={descriptionModalOpen}
                  onClose={() => setDescriptionModalOpen(false)}
                >
                  <div>
                    <EditDescriptionModal
                      details={details}
                      setSnackbarText={setSnackbarText}
                      handleClose={() => setDescriptionModalOpen(false)}
                      mutate={mutate}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </Modal>
                <Modal
                  open={disclaimerModalOpen}
                  onClose={() => setDisclaimerModalOpen(false)}
                >
                  <div>
                    <EditDisclaimerModal
                      details={details}
                      setSnackbarText={setSnackbarText}
                      handleClose={() => setDisclaimerModalOpen(false)}
                      mutate={mutate}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </Modal>
              </>
            )}
          </form>
        )}
      </Formik>
      <Modal open={Boolean(errorAlert)} onClose={() => setErrorAlert(null)}>
        <div>
          <BasicsErrorModal errorObj={errorAlert} />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default BasicsForm;

BasicsForm.propTypes = {
  details: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,

  isModuleLive: PropTypes.bool.isRequired,
  isModulePending: PropTypes.bool.isRequired,
  isModuleConvertingNextStateLive: PropTypes.bool.isRequired,
  isModuleConvertingNextStatePending: PropTypes.bool.isRequired,
};
