/* eslint-disable */
import React from "react";
import { LinearProgress } from "@material-ui/core";
import styled from "styled-components/macro";
import { UploadCloud } from "react-feather";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: aliceblue;
  z-index: 2;
  top: 0px;
  left: 0px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-direction: column;
  border: 1px solid #337ab7;
`;

const CustomLinearProgress = styled(LinearProgress)`
  .MuiLinearProgress-barColorPrimary {
    background-color: #337ab7;
    border-radius: 8px;
  }
`;

const UploadingPdfOverlay = () => {
  return (
    <Wrapper>
      <div
        style={{
          background: "#337ab7",
          padding: 4,
          borderRadius: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 24,
          height: 24,
        }}
      >
        <UploadCloud color="white" />
      </div>
      <span style={{ fontWeight: "500", color: "black" }}>
        PDF Uploading...
      </span>
      <CustomLinearProgress
        style={{ width: 170, borderRadius: 8, color: "black" }}
      />
    </Wrapper>
  );
};

export default UploadingPdfOverlay;
