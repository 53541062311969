/* eslint-disable */
import React from "react";
import { AlertTriangle } from "react-feather";
import styled from "styled-components/macro";
import ToolbarStateAndPreview from "./ToolbarStateAndPreview";
import RequiredFieldsIndicator from "./RequiredFieldsIndicator";

const Wrapper = styled.div`
  width: 100%;
  height: 42px;
  background: #eeeeee88;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const WrapperLiveModule = styled(Wrapper)`
  background: #eeeeee88;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  justify-content: space-between;
`;

const BottomToolbar = ({
  title,
  steps,
  stepIndex,
  details,
  isModulePending,
  isModuleLive,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStateDev,
  isModuleConvertingNextStatePending,
  isRetail,
}) => {
  const required = steps[stepIndex].requiredFields;
  const completed = steps[stepIndex].completedFields;
  const isOnLaunchPage = title === "Launch";

  if (isModuleLive) {
    return (
      <WrapperLiveModule>
        <RequiredFieldsIndicator
          required={required}
          completed={completed}
          title={title}
          isModuleLive={isModuleLive}
          isRetail={isRetail}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
        />
        <ToolbarStateAndPreview
          details={details}
          isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
          isModuleConvertingNextStatePending={
            isModuleConvertingNextStatePending
          }
        />
      </WrapperLiveModule>
    );
  }

  if (isOnLaunchPage) {
    if (steps[stepIndex].complete) {
      //  all required fields are filled
      return (
        <Wrapper>
          <div style={{ display: "flex", gap: 4 }}>
            <span style={{ fontWeight: "600" }}>{title}:</span>
            {isModulePending || isModuleConvertingNextStatePending ? (
              <span style={{ color: "darkgrey", fontWeight: "500" }}>
                Module will be launched automatically on start date
              </span>
            ) : isModuleConvertingNextStateLive ? (
              <span style={{ color: "darkgrey", fontWeight: "500" }}>
                Module will be launched when video finishes transcoding
              </span>
            ) : (
              <span style={{ color: "seagreen", fontWeight: "500" }}>
                Ready to launch 🚀
              </span>
            )}
          </div>
          <ToolbarStateAndPreview
            details={details}
            isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
            isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
            isModuleConvertingNextStatePending={
              isModuleConvertingNextStatePending
            }
          />
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div style={{ display: "flex", gap: 4 }}>
          <span style={{ fontWeight: "600" }}>{title}:</span>
          <span
            style={{
              color: "darkgrey",
              fontWeight: "500",
              whiteSpace: "nowrap",
            }}
          >
            Complete all required fields to launch module.
          </span>
        </div>
        <div style={{ width: 4 }} />
        <div
          style={{
            heigth: 20,
            width: 20,
            minWidth: 20,

            minHeight: 20,
            borderRadius: 3,
            padding: 2,
            background: "white",
            border: "1px solid lightgray",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AlertTriangle height={12} width={12} color="darkorange" />
        </div>
        <ToolbarStateAndPreview
          details={details}
          isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
          isModuleConvertingNextStatePending={
            isModuleConvertingNextStatePending
          }
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {required && (
        <RequiredFieldsIndicator
          required={required}
          completed={completed}
          title={title}
          isModuleLive={isModuleLive}
          isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
          isRetail={isRetail}
        />
      )}
      <ToolbarStateAndPreview
        isModuleConvertingNextStateDev={isModuleConvertingNextStateDev}
        isModuleConvertingNextStateLive={isModuleConvertingNextStateLive}
        isModuleConvertingNextStatePending={isModuleConvertingNextStatePending}
        details={details}
      />
    </Wrapper>
  );
};

export default BottomToolbar;
