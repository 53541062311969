/* eslint-disable */
import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import { AlertTriangle } from "react-feather";

const Wrapper = styled(Paper)`
  padding: 20px;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: start;
  border-radius: 8px;
  border: 2px solid tomato;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BasicsErrorModal = ({ errorObj }) => {
  return (
    <Wrapper>
      <AlertTriangle color="tomato" height={18} width={18} />
      <div style={{ height: 4 }} />
      <p style={{ fontWeight: "600", fontSize: 14, lineHeight: 1 }}>
        Failed to update field.
      </p>
      <div style={{ height: 6 }} />
      {errorObj?.detail.map((detail) => (
        <p key={detail}>{detail}</p>
      ))}
    </Wrapper>
  );
};

export default BasicsErrorModal;
