/* eslint-disable */
import React from "react";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import useQuizReportList from "../../../../../swr/hooks/RetailReports/useQuizReportList";
import Loader from "../../vendorReports/Loader";
import ModulesTable from "./ModulesTable";

const ModulesReport = () => {
  const { quizzes, isLoading, isError } = useQuizReportList();

  if (isLoading) return <Loader />;
  if (isError) return <ErrorBlockWithPermissions errorObj={isError} />;

  return <ModulesTable modules={quizzes.quizzes} />;
};

export default ModulesReport;
