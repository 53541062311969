/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { DropzoneArea } from "material-ui-dropzone";
import { CircularProgress, Collapse } from "@material-ui/core";
import { Alert, Button } from "../../styles";
import UploadPdfOverlay from "../UI/UploadingPdfOverlay";
import { UploadCloud } from "react-feather";
const MAXFILESIZE = 100000000; // 100 MB

const DropzonePDF = ({
  setPdfAlert,
  setUploadPDF,
  pdfAlert,
  uploadPDF,
  handlePdfUpload,
  dropzoneKey,
  pdfUploading,
  setSnackbarText,
}) => {
  const handleReject = (file) => {
    const { type, size } = file[0] || {};
    if (type !== "application/pdf") {
      return setSnackbarText("Make sure your document is a PDF error");
    }
    if (size > MAXFILESIZE) {
      return setSnackbarText(
        "PDF is too large. Make sure your PDF is 100mb or less error"
      );
    }
    return setSnackbarText(
      "Failed to handle your PDF. Ensure you are uplod a locally saved file. (Not from G Drive or Share Point). Also review the file specs and ensure they align with our recommendations. error"
    );
  };

  return (
    <>
      <div style={{ width: "100%", position: "relative" }}>
        {pdfUploading && <UploadPdfOverlay />}
        <DropzoneArea
          acceptedFiles={["application/pdf"]}
          showFileNamesInPreview
          showFileNames
          getFileAddedMessage={(fileName) => setPdfAlert(fileName)}
          filesLimit={1}
          maxFileSize={MAXFILESIZE}
          onDropRejected={(file) => handleReject(file)}
          onChange={(file) => setUploadPDF(file)}
          key={dropzoneKey}
          showAlerts={false}
          dropzoneText={
            <div
              style={{
                height: 80,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: 16 }}>Drop a pdf here</span>
              <span style={{ fontSize: 13, color: "darkgrey" }}>
                Accepts .pdf files
              </span>
            </div>
          }
        />
      </div>
      <Collapse
        in={pdfAlert && uploadPDF.length === 1}
        style={{ width: "100%" }}
      >
        <>
          <Alert severity="info" mt={2} style={{ width: "100%" }}>
            <strong>{pdfAlert}</strong> meets requirements. Upload to finalize.
          </Alert>
          <Button
            startIcon={
              pdfUploading ? (
                <CircularProgress size={20} />
              ) : (
                <UploadCloud color="white" height={15} width={15} />
              )
            }
            mt={2}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handlePdfUpload}
            disabled={pdfUploading}
          >
            Upload
          </Button>
        </>
      </Collapse>
    </>
  );
};

export default DropzonePDF;

DropzonePDF.propTypes = {
  setPdfAlert: PropTypes.func.isRequired,
  setUploadPDF: PropTypes.func.isRequired,
  pdfAlert: PropTypes.string.isRequired,
  handlePdfUpload: PropTypes.func.isRequired,
  uploadPDF: PropTypes.array.isRequired,
  dropzoneKey: PropTypes.bool.isRequired,
  pdfUploading: PropTypes.bool.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
