/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";
import { getClientTimezone } from "../../../storage/helpers";

const { timezoneQueryStr, timezone } = getClientTimezone();

const BASE_URL = `/dashboard/home/`;

const fetcher = async (url, locationId) => {
  const { data } = await API.get(
    locationId
      ? `${url}?location_id=${locationId}&timezone=${timezone}`
      : `${url}${timezoneQueryStr}`
  );
  return data;
};

const useSupervisorHome = (locationId = null) => {
  const { data, error, mutate } = useSWR([BASE_URL, locationId], fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSupervisorHome;
