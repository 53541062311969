/* eslint-disable */
import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import ConfirmRemoveLocation from "./ConfirmRemoveLocation";
import { CustomDatatableV2, StyledLink } from "../../styles";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import DeleteButtonForTables from "../../../../components/Table/DeleteButtonForTables";
import TableTitle from "../../../../components/Table/TableTitle";
import { MapPin } from "react-feather";
import SuperDeloyTooltip from "../../locations/SuperDeployTooltip";

const SupervisorLocationTable = ({
  locations,
  managerId,
  mutate,
  readOnly,
  managerName,
}) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");

  const options = makeDefaultTableOptions(
    `locations-supervised-${managerName}`
  );
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Location",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/locations/detail/${tableMeta.rowData[0]}`}
            value={value}
          />
        ),
      },
    },
    {
      name: "supervisorDeploy",
      label: "Deploy Permissions",
      options: {
        filter: false,
        sort: false,
        download: true,
        print: false,
        display: true,
        customBodyRender: (value) => <SuperDeloyTooltip value={value} />,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "delete",
      label: "Remove",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setDeleteInfo({
                locationId: rowData[0],
                locationName: `${rowData[1]}`,
                name: managerName,
              });
            }}
          />
        ),
      },
    },
  ];

  const tableData = locations.map((location) => ({
    id: location.id,
    name: location.name,
    address: `${location.address.line1}, ${location.address.city.name}, ${location.address.state.name}, ${location.address.country.name}`,
    supervisorDeploy: location.managers_can_deploy_content ? "Yes" : "No",
  }));

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle Icon={MapPin} title="Locations" />}
        data={tableData}
        columns={columns}
        options={options}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Remove Supervisor from this Location"
        aria-describedby="Remove Supervisor from this Location"
      >
        <div>
          <ConfirmRemoveLocation
            setSnackbarText={setSnackbarText}
            deleteInfo={deleteInfo}
            managerId={managerId}
            setDeleteInfo={setDeleteInfo}
            mutate={mutate}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default SupervisorLocationTable;

SupervisorLocationTable.propTypes = {
  locations: PropTypes.array.isRequired,
  managerId: PropTypes.string.isRequired,
  mutate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
