/* eslint-disable */
import React from "react";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import useModuleReport from "../../../../../swr/hooks/VendorReports/useModuleReport";
import Loader from "../Loader";
import QuizzesTable from "./QuizzesTable";

const QuizBreakdown = () => {
  const { isLoading, isError, data } = useModuleReport();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) return <ErrorBlockWithPermissions errorObj={isError} />;

  return <QuizzesTable quizzes={data.quizzes_breakdown} />;
};

export default QuizBreakdown;
