/* eslint-disable */
import React from "react";
import { Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import { Lock, X } from "react-feather";

const Wrapper = styled(Paper)`
  padding: 20px;
  width: 460px;
  min-height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid black;
  // position: relative;
`;

const CloseButton = styled.div`
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 8px;
  background: #eeeeee;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: gainsboro;
  }

  svg {
    color: black;
    height: 14px;
    width: 14px;
  }
`;

const NoPermissionsModal = ({ displayPermissionsError, handleClose }) => {
  return (
    <Wrapper>
      <Lock color="firebrick" height={18} width={18} />
      <div style={{ height: 4 }} />

      {/* <span style={{ fontSize: 14, fontWeight: "600" }}>Permissions Error</span> */}
      <span style={{ fontSize: 14, fontWeight: "600", textAlign: "center" }}>
        {displayPermissionsError?.title}
      </span>
      <div style={{ height: 4 }} />
      <span style={{ color: "darkgrey", textAlign: "center", fontSize: 12 }}>
        {displayPermissionsError?.detail}
      </span>
      <CloseButton onClick={handleClose}>
        <X />
      </CloseButton>
    </Wrapper>
  );
};

export default NoPermissionsModal;
