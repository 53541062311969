/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components/macro";
import { Paper } from "@material-ui/core";
import { GlobalState } from "../../../../store/GlobalState";
import ModulesReport from "./modulesReport";
import { TextField, Typography } from "../../styles";
import LocationsReport from "./locationReport";
import TeamMembersReports from "./teamMemberReport";
import { RETAIL_REPORT_TYPES } from "../../../../storage/constants";
import OverallReport from "./overallReport";
// import PolarReport from "./PolarReport";
import FeedbackReport from "./feedbackReport";
// import { Lock } from "react-feather";
import ProFeatureChip from "../../../../components/UI/ProFeatureChip";

const RETAIL_REPORT_KEY = "spiffyRRS";

const ReportWrapper = styled(Paper)`
  padding: 20px 20px 20px 20px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const REPORT_COMONENT_MAP = {
  [RETAIL_REPORT_TYPES.MODULES]: ModulesReport,
  [RETAIL_REPORT_TYPES.LOCATIONS]: LocationsReport,
  [RETAIL_REPORT_TYPES.TEAM_MEMBERS]: TeamMembersReports,
  [RETAIL_REPORT_TYPES.COUNTS]: OverallReport,
  [RETAIL_REPORT_TYPES.FEEDBACK]: FeedbackReport,
};

const SelectReportTypeRetail = () => {
  const [reportType, setReportType] = useState(null);

  const { state } = useContext(GlobalState);
  const { config } = state;
  const { current_company: currentCompany } = config;
  const { permissions } = currentCompany;
  const { fields } = permissions;

  const availableReports = [
    {
      name: "Overview",
      id: RETAIL_REPORT_TYPES.COUNTS,
      hasPermissions: fields.overview_report,
    },
    {
      name: "Locations Report",
      id: RETAIL_REPORT_TYPES.LOCATIONS,
      hasPermissions: fields.location_report,
    },
    {
      name: "Modules Report",
      id: RETAIL_REPORT_TYPES.MODULES,
      hasPermissions: fields.module_report,
    },
    {
      name: "Team Members Report",
      id: RETAIL_REPORT_TYPES.TEAM_MEMBERS,
      hasPermissions: fields?.team_member_report,
    },
    {
      name: "Feedback Report",
      id: RETAIL_REPORT_TYPES.FEEDBACK,
      hasPermissions: fields?.feedback_report,
    },
  ];

  useEffect(() => {
    if (!reportType) return undefined;

    return localStorage.setItem(RETAIL_REPORT_KEY, reportType.id);
  }, [reportType]);

  useEffect(() => {
    const item = localStorage.getItem(RETAIL_REPORT_KEY);

    if (item && !(item === "OVERALL")) {
      return setReportType(availableReports.find((rep) => rep.id === item));
    }

    return setReportType(availableReports[0]);
  }, []);

  if (!reportType) return null;

  const ReportComponent = REPORT_COMONENT_MAP[reportType.id];

  return (
    <>
      <ReportWrapper>
        <Typography variant="h5" mb={4}>
          Choose Report Type
        </Typography>
        <Autocomplete
          disableClearable
          options={availableReports}
          noOptionsText="No Reports"
          value={reportType}
          renderOption={(option) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>{option.name}</span>
              {option.hasPermissions ? <div /> : <ProFeatureChip />}
            </div>
          )}
          getOptionLabel={(option) => option.name}
          onChange={(_, newValue) => setReportType(newValue)}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Reports Available"
              variant="outlined"
            />
          )}
        />
      </ReportWrapper>
      <ReportComponent />
    </>
  );
};

export default SelectReportTypeRetail;
