/* eslint-disable */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from "react";
import { Chip, useMediaQuery } from "@material-ui/core";
import { Calendar, MapPin, User, Users } from "react-feather";
import styled from "styled-components/macro";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import useTeamMemberProgress from "../../../../../swr/hooks/Team/useTeamMemberProgress";
import {
  CenterWithColor,
  CustomDatatableV2,
  StyledLink,
} from "../../../styles";
import DateRange from "../../filter/DateRange";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import ProgressBarV2 from "../../../../../components/Table/ProgressBarV2";
import { retOutstandingV2, retProgressV2 } from "../../utils/utils";

const ENGAGEMENT_TYPES = {
  ALL: "All",
  ASSIGNED: "Assigned",
  EXTRACREDIT: "Extra Credit",
};

const CustomChip = styled(Chip)`
  border-radius: 4px;
  margin-left: 12px;
  border: 1px solid lightgrey;
  background-color: aliceblue;
  height: 28px;
`;

const getPassRate = (stats, engagementType) => {
  const comps =
    engagementType === ENGAGEMENT_TYPES.ALL
      ? stats.assigned_completions + stats.extra_credit_completions
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
      ? stats.extra_credit_completions
      : stats.assigned_completions;

  const subs =
    engagementType === ENGAGEMENT_TYPES.ALL
      ? stats.assigned_submissions + stats.extra_credit_submissions
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
      ? stats.extra_credit_submissions
      : stats.assigned_submissions;

  return comps > 0 ? Math.floor((comps / subs) * 100) : 0;
};

const getTableData = (teamMembers, engagementType, progress) => {
  if (!teamMembers) return [];

  return teamMembers.map((member) => ({
    id: member.id,
    userId: member.user_id,
    name: `${member.first_name} ${member.last_name}`,
    // created: new Date(member.created).toISOString().split('T')[0],
    outstanding: retOutstandingV2(member.user_id, progress),
    email: member.email,
    location: member.location_name,
    spiffyScore: member.spiffy_score || 0,
    progress: retProgressV2(member.user_id, progress),
    submissions:
      engagementType === ENGAGEMENT_TYPES.ALL
        ? member.submission_statistics.assigned_submissions +
          member.submission_statistics.extra_credit_submissions
        : engagementType === ENGAGEMENT_TYPES.ASSIGNED
        ? member.submission_statistics.assigned_submissions
        : member.submission_statistics.extra_credit_submissions,
    completions:
      engagementType === ENGAGEMENT_TYPES.ALL
        ? member.submission_statistics.assigned_completions +
          member.submission_statistics.extra_credit_completions
        : engagementType === ENGAGEMENT_TYPES.ASSIGNED
        ? member.submission_statistics.assigned_completions
        : member.submission_statistics.extra_credit_completions,
    passRate: getPassRate(member.submission_statistics, engagementType),
    locationId: member.location_id,
  }));
};

const MemberTable = ({
  teamMembers,
  filterDateString,
  setFilterDateString,
}) => {
  const { progress, isError, isLoading } = useTeamMemberProgress();

  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const tableData = useMemo(
    () => getTableData(teamMembers, engagementType, progress),
    [engagementType, isLoading, filterDateString]
  );

  const options = makeDefaultTableOptions("team-members-report");
  options.rowsPerPage = 50;
  options.downloadOptions.filterOptions.useDisplayedColumnsOnly = false;
  options.textLabels = {
    body: {
      noMatch:
        "No team members on your team yet. Invite some new team members to populate this list.",
    },
  };
  options.sortOrder = {
    name: "spiffyScore",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "userId",
      label: "User id",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/teammembers/detail/${tableMeta.rowData[0]}`}
            value={value}
            CustomIcon={User}
          />
        ),
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: false,
        sort: true,
        display: true,
        print: true,
        display: false,
        download: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/locations/detail/${tableMeta.rowData[12]}`}
            value={value}
            CustomIcon={MapPin}
          />
        ),
      },
    },
    {
      name: "created",
      label: "Member Since",
      options: {
        filter: true,
        print: false,
        sort: true,
        display: false,
        download: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "completions",
      label: " Completions",
      options: {
        filter: true,
        sort: true,
        download: true,
        customBodyRender: (value) => {
          return (
            <CenterWithColor engagementType={engagementType}>
              <span>{value}</span>
            </CenterWithColor>
          );
        },
      },
    },
    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        download: true,
        customBodyRender: (value) => {
          return (
            <CenterWithColor engagementType={engagementType}>
              <span>{value}</span>
            </CenterWithColor>
          );
        },
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        filter: true,
        sort: true,
        download: true,
        customBodyRender: (value) => {
          return (
            <CenterWithColor engagementType={engagementType}>
              <span>{value}%</span>
            </CenterWithColor>
          );
        },
      },
    },
    {
      name: "outstanding",
      label: "Outstanding Modules",
      options: {
        filter: false,
        sort: true,
        download: true,
        display: false,
        customBodyRender: (value) => {
          if (
            engagementType === ENGAGEMENT_TYPES.EXTRACREDIT ||
            filterDateString
          ) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }
          return <span>{value}</span>;
        },
      },
    },
    {
      name: "progress",
      label: "Progress",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (_, tableMeta) => {
          if (
            engagementType === ENGAGEMENT_TYPES.EXTRACREDIT ||
            filterDateString
          ) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }
          return (
            <ProgressBarV2
              progress={progress}
              id={tableMeta.rowData[1]}
              isError={isError}
              isLoading={isLoading}
            />
          );
        },
      },
    },
    {
      name: "spiffyScore",
      label: "Spiffy Score",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          if (filterDateString) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
              }}
            >
              <span style={{ fontWeight: "600" }}>{value}</span>
            </div>
          );
        },
      },
    },
    {
      name: "locationId",
      label: "",
      options: {
        filter: false,
        sort: false,
        print: false,
        display: false,
        download: false,
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 22,
          marginBottom: 12,
        }}
      >
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
        />
        <DateRange
          filterDateString={filterDateString}
          setFilterDateString={setFilterDateString}
          setEngagementType={setEngagementType}
        />
      </div>
      <CustomDatatableV2
        title={
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <TableTitle
              Icon={Users}
              title="Team Member Report"
              submissionType={engagementType}
            />
            {filterDateString && (
              <CustomChip
                label={filterDateString.readString}
                clickable
                size="small"
                onDelete={() => setFilterDateString(null)}
                icon={
                  <Calendar style={{ height: 14, width: 14, color: "black" }} />
                }
              />
            )}
            {!filterDateString && engagementType === ENGAGEMENT_TYPES.ALL && (
              <span
                style={{ fontSize: 12, color: "lightgrey", marginLeft: 12 }}
              >
                No Filters Applied
              </span>
            )}
          </div>
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default MemberTable;
