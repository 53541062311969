/* eslint-disable */
import React, { useContext } from "react";
import styled from "styled-components/macro";
import { GlobalState } from "../../../../store/GlobalState";
import { Typography } from "../../styles";
import ReactCountryFlag from "react-country-flag";
import UserBox from "../reuseableComponents/UserBox";

const CompanyBox = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  position: relative;
`;

const IconContainer = styled.div`
  border-radius: 12px;
  background-color: aliceblue;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  overflow-x: hidden;
`;

const Hometitle = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: black;
`;

const LogoImage = styled.img`
  height: 70px;
  width: 120px;
  object-fit: contain;
  border-radius: 8px;
  filter: grayscale(75%);
 color: 'lightgray'

  &:hover {
    filter: grayscale(0%);
  }
`;

const MarketplaceContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoLogoDiv = styled.div`
  height: 70px;
  width: 120px;
  border-radius: 8px;
  background-color: #eeeeee66;
`;

const HoldMarketplaces = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
`;

const RightSide = () => {
  const { state } = useContext(GlobalState);
  const { config } = state;

  const { current_company: company } = config;

  const hasLogo = Boolean(company.logo);

  return (
    <>
      <CompanyBox>
        {hasLogo ? <LogoImage alt="" src={company.logo} /> : <NoLogoDiv />}
        <Hometitle>{company.name}</Hometitle>
        <MarketplaceContainer>
          {company.available_marketplaces.map((mp, i) => (
            <HoldMarketplaces key={mp.country_code}>
              <ReactCountryFlag
                countryCode={mp.country_code}
                style={{
                  fontSize: 16,
                  marginRight: 4,
                  marginLeft: i > 0 ? 6 : 2,
                }}
              />
              <span
                style={{ color: "darkgrey", fontSize: 12, fontWeight: "500" }}
              >
                {mp.country}
              </span>
            </HoldMarketplaces>
          ))}
        </MarketplaceContainer>
      </CompanyBox>
      {/* <UserBox /> */}
    </>
  );
};

export default RightSide;
