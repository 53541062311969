/* eslint-disable */
/* eslint-disable max-len */
import { darken } from "polished";
import { X, XCircle, Shuffle, Link as LinkIcon } from "react-feather";
import styled, { keyframes } from "styled-components/macro";
import {
  Button as MuiButton,
  Paper,
  CircularProgress,
  Chip,
  Box,
  Tooltip,
  LinearProgress,
  Typography as MuiTypography,
  Grid as MuiGrid,
  AppBar,
  Avatar,
  IconButton,
  TextField as MuiTextfield,
  Divider as MuiDivider,
  Tabs,
  RadioGroup as MuiRadioGroup,
  FormControl as MuiFormControl,
  Badge,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import MUIDataTable from "mui-datatables";
import { spacing } from "@material-ui/system";
import { Link } from "react-router-dom";
import { QUIZSTATES, ENGAGEMENT_TYPES } from "../../../storage/constants";

// Often used Spaced Material UI Components
export const Button = styled(MuiButton)(spacing);
export const Typography = styled(MuiTypography)(spacing);
export const TextField = styled(MuiTextfield)(spacing);
export const Alert = styled(MuiAlert)(spacing);
export const Divider = styled(MuiDivider)(spacing);
export const RadioGroup = styled(MuiRadioGroup)(spacing);
export const FormControlSpacing = styled(MuiFormControl)(spacing);
export const Grid = styled(MuiGrid)(spacing);

export const CmPointerContainer = styled.div`
  height: 14px;
  width: 46px;
  background: #eeeeee;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 8px;
    color: #337ab7;
  }

  &:hover {
    cursor: pointer;
    background: lightgray;
  }
`;

export const MpPointerContainer = styled.div`
  height: 14px;
  width: 18px;
  background: #907ac63b;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 600;
    font-size: 8px;
    color: #813eee;
  }

  &:hover {
    cursor: pointer;
    background: lightgray;
  }
`;

export const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px) scale(.85);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
`;

export const ShowSpiffyScore = styled.div`
  background-color: #eeeeee;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  justify-content: flex-start;

  span {
    color: black;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const CustomDatatable = styled(MUIDataTable)`
  .MuiToolbar-regular {
    @media print {
      display: block !important;
    }
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 16px;
    margin-left: -8px;
    color: ${(props) => props.theme.palette.grey[500]};
  }
  text-align: left;
`;

export const CustomDatatableV2 = styled(MUIDataTable)`
  border-radius: 6px;

  .MuiToolbar-regular {
    @media print {
      display: block !important;
    }
  }

  .MuiSvgIcon-fontSizeSmall {
    font-size: 14px;
    border-radius: 4px;
    padding: 0px;

    margin-left: -8px;
    color: ${(props) => props.theme.palette.grey[500]};
  }

  .MuiTableCell-footer {
    border: none;
  }

  .MuiTableFooter-root {
    background: white;
  }

  // .MuiDataTableJumpToPage {
  //   border: 1px solid blue;
  // }

  .MuiIconButton-root {
    padding: 0px;
    border-radius: 0px;
    cursor: pointer;
    margin-left: 4px;

    &:hover {
      background: transparent;
    }
  }

  text-align: left;
`;

export const CustomTabbedDatatable = styled(CustomDatatableV2)`
  border-top-left-radius: 0px;
`;

export const RoundedDataTable = styled(CustomDatatable)`
  border-radius: 20px;
  border-bottom: 1px solid rgba(224, 224, 224, 0.25);

  .MuiTableCell-footer {
    border: none;
  }

  .MuiPaper-rounded {
    border-radius: 20px;
  }
`;

export const ShowState = styled.div`
  border-radius: 4px;
  height: 20px;
  padding: 1px 4px 1px 10px;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: #eeeeee84;

  &:before {
    content: " ";
    position: relative;
    left: -6px;
    height: 11px;
    width: 11px;
    border-radius: 3px;
    background-color: ${(props) => {
      if (props.state === QUIZSTATES.LIVE) return "mediumseagreen";
      if (props.state === QUIZSTATES.CLOSED) return "tomato";
      if (props.state === QUIZSTATES.IN_DEVELOPMENT) return "#71a5bb";
      if (props.state === QUIZSTATES.PENDING) return "orange";
      if (props.state === QUIZSTATES.CONVERSION) return "slategrey";
      return "white";
    }};
  }

  span {
    font-weight: 500;
    font-size: ${(props) => (props.small === "Yes" ? 10 : 12)}px;
    white-space: nowrap;
    color: black;
  }
`;

export const MarketplaceChip = styled.div`
  padding: 0px 8px;
  border-radius: 4px;
  background-color: #eeeeee;
  text-align: center;
  display: flex;
  align-items: center;
  height: 16px;
  justify-content: center;
  margin-left: 2px;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }

  span {
    color: slategrey;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const MarketplaceChipToolTip = ({ textOverride = null }) => (
  <Tooltip title={textOverride || "Module downloaded from marketplace"}>
    <MarketplaceChip>
      <span>Marketplace</span>
    </MarketplaceChip>
  </Tooltip>
);

export const CustomChip = styled.div`
  padding: 0px 8px;
  border-radius: 4px;
  background-color: #004aff2b;
  text-align: center;
  margin-left: 2px;
  width: fit-content;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #7246a8e0;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const CustomChipTooltip = ({ textOverride = null }) => (
  <Tooltip title={textOverride || "Custom module created by your team"}>
    <CustomChip>
      <span>Custom</span>
    </CustomChip>
  </Tooltip>
);

export const SubmissionChipAssigned = styled.div`
  background-color: rgba(0, 0, 250, 0.1);
  height: 18px;
  padding: 4px 6px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #587dea;
    font-weight: 800;
    font-size: 10px;
  }
`;

const SvgImage = styled.img`
  height: 28px;
  width: 28px;
  transform: translateY(1px);
`;

export const SubmissionChipAssignedTooltip = ({ title = null }) => (
  <Tooltip title={title || "Assigned Submisson"} arrow placement="right">
    <SubmissionChipAssigned>
      <span>A</span>
    </SubmissionChipAssigned>
  </Tooltip>
);

export const SubmissionChipExtraCreditTooltip = ({
  title = null,
  isVendor = false,
}) => (
  <Tooltip
    title={title || isVendor ? "Follower Submission" : "Extra Credit Submisson"}
    placement="right"
    arrow
  >
    <SubmissionChipExtraCredit>
      <span>{isVendor ? "F" : "EC"}</span>
    </SubmissionChipExtraCredit>
  </Tooltip>
);

export const CenterWithColor = styled.div`
  height: ${(props) => (props.small === "yes" ? 22 : 34)}px;
  width: ${(props) => (props.small === "yes" ? 28 : 46)}px;
  min-width: fit-content;
  display: flex;
  padding: 1px 2px;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  background-color: ${(props) => {
    if (props.engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return "rgba(0, 0, 250, .05)";
    }
    if (props.engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return "#ffeeb066";
    }
    return "white";
  }};

  border: ${(props) => {
    if (props.engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return "1px solid #587dea";
    }
    if (props.engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return "1px solid #aa9700";
    }

    return "none";
  }};

  span {
    color: ${(props) => {
      if (props.engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
        return "#587dea";
      }

      if (props.engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
        return "#aa9700";
      }

      return "black";
    }};
    font-weight: ${(props) =>
      props.engagementType !== ENGAGEMENT_TYPES.ALL ? "500" : "400"};
  }
`;

export const SubmissionChipExtraCredit = styled.div`
  background-color: #ffeeb091;
  height: 18px;
  padding: 4px 6px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    color: #aa9700;
    font-weight: 800;
    font-size: 10px;
  }
`;

const Slink = styled(Link)`
  text-decoration: none;
  color: black;
  font-weight: 400;
  &:hover {
    color: ${(props) => props.theme.palette.info.light};
    text-decoration: underline;
  }
`;

export const StyledLink = ({ value, to, CustomIcon = null, small = false }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",

        maxWidth: 280,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {CustomIcon ? (
        <CustomIcon
          style={{
            height: 14,
            width: 14,
            minWidth: 14,
            color: "dodgerblue",
            padding: 3,
            backgroundColor: "#eeeeee",
            borderRadius: 4,
            marginRight: 4,
          }}
        />
      ) : (
        <LinkIcon
          style={{
            height: 14,
            width: 14,
            color: "dodgerblue",
            padding: 3,
            backgroundColor: "#eeeeee",
            borderRadius: 4,
            marginRight: 4,
          }}
        />
      )}
      <Slink
        to={to ? to : "/"}
        style={{
          fontSize: small ? 10 : 13,
          lineHeight: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: 280,
        }}
      >
        {value}
      </Slink>
    </div>
  );
};

export const ReportLink = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    color: ${(props) => props.theme.palette.info.light};
    text-decoration: underline;
  }
`;

export const FormControl = styled(FormControlSpacing)`
  min-width: 100%;
`;

export const QuizBadge = styled(Badge)`
  .MuiBadge-dot {
    opacity: 0.75;
    background-color: ${(props) => {
      if (props.input === QUIZSTATES.LIVE)
        return props.theme.palette.success.light;
      if (props.input === QUIZSTATES.CONVERSION)
        return props.theme.palette.warning.dark;
      if (props.input === QUIZSTATES.CLOSED) return "tomato";
      if (props.input === QUIZSTATES.IN_DEVELOPMENT)
        return props.theme.palette.info.light;
      if (props.input === QUIZSTATES.PENDING)
        return props.theme.palette.warning.light;
      return "white";
    }};
    transform: translate(-12px, -4px);
  }
`;

// Reusable Delete Modal Components
export const DeleteTimes = styled(X)`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  height: 28px;
  width: 28px;
`;

export const DeleteButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.error.main};
  color: ${(props) => props.theme.palette.error.contrastText};
  border-radius: 6px;

  &:hover {
    background-color: ${(props) =>
      darken(0.12, props.theme.palette.error.main)};
  }

  &:disabled {
    background-color: ${(props) => darken(0.15, props.theme.palette.grey[300])};
    color: black;
  }
`;

export const DeleteCircularProgress = styled(CircularProgress)`
  color: white;
`;

// Reusable Icons

export const BlueShuffle = styled(Shuffle)`
  color: ${(props) => props.theme.palette.primary.light};
  transform: translateY(4px);
  margin-left: 4px;
`;

// Regular Icons

export const DeleteIcon = styled(XCircle)`
  color: ${(props) => props.theme.palette.error.light};
  cursor: pointer;
  height: 22px;
  width: 22px;
`;

// Wrappers

export const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(5)}px;
  min-height: 220px;
  min-width: 300px;
  text-align: left;
`;

export const ModalWrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(10)}px;
  min-width: min(600px, 100vw);
  max-width: 600px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const DeleteWrapper = styled(Paper)`
  padding: 20px;
  width: min(520px, 98vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 8px;
`;

// Other

export const Question = styled(Typography)`
  color: black;
  font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
  font-weight: ${(props) => props.theme.typography.h4.fontWeight};
`;

export const Answer = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[800]};
  font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
  font-weight: ${(props) => props.theme.typography.subtitle1.fontWeight};
  margin-left: auto;
`;

// Tab Components

export const TableTabBar = styled(AppBar)`
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  top: 3px;
  right: 1px;
  z-index: 10;
  width: fit-content;
`;

export const TableTabs = styled(Tabs)`
  .MuiTab-root {
    border-top: 1px solid rgba(112, 128, 144, 0.2);
    border-left: 1px solid rgba(112, 128, 144, 0.2);
    border-right: 1px solid rgba(112, 128, 144, 0.2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #eee;
    opacity: 1;
    margin-right: 1px;

    @media (max-width: 900px) {
      min-width: 0px;
    }
  }
  .MuiTab-root.Mui-selected {
    background-color: #fff;
  }

  .MuiTab-wrapper {
    color: black;
    font-size: 14px;
    min-width: 240px;

    @media (max-width: 1450px) {
      min-width: 180px;
      font-size: 12px;
    }

    @media (max-width: 900px) {
      min-width: 0px;
      font-size: 10px;
      white-space: wrap;
      padding: 0;
    }

    @media (max-width: 560px) {
      font-size: 8px;
    }
  }
`;

export const SubtitleSmall = styled(Typography)`
  font-size: 11px;
  color: ${(props) => props.theme.palette.grey[500]};
`;

export const CustomLinearProgressMain = styled(LinearProgress)`
  
  background-color: #EEEEEE;
  border-radius: 8px;
  min-height: 14px;
  height: 15px;
  width: 100%;
  margin-right: 8px;
  border: 1px solid #eeeeee;
  .MuiLinearProgress-barColorPrimary {
    background-color: #337AB7;
  }
}
`;
// .MuiLinearProgress-barColorPrimary {
//   background-color: ${props => {
//     if (props.value > 80) return props.theme.palette.success.main;
//     if (props.value > 60) return props.theme.palette.success.light;
//     if (props.value > 40) return props.theme.palette.warning.light;
//     if (props.value > 20) return props.theme.palette.warning.main;
//     if (props.value > 0) return props.theme.palette.error.main;
//     return props.theme.palette.primary.main;
//   }};

export const WrapperLessPaddingTop = styled(Paper)`
  padding: 10px 20px 20px 20px;
  margin-top: 16px;
  // border: 1px solid lightgray;
  border-radius: 6px;
`;

export const AssignedAvatar = styled(Avatar)`
  background-color: lightblue;
  height: 12px;
  width: 12px;
  margin-right: 8px;
  font-size: 8px;
  color: black;
`;

export const FollowerAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.palette.grey[500]};
  height: 12px;
  width: 12px;
  margin-right: 8px;
  font-size: 8px;
  color: white;
`;

export const InfoHiddenWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  background-color: white;
  transition: all 220ms ease-out;

  transform: ${(props) => {
    if (props.hidden === "yes") {
      return "translateX(-110%)";
    }
    return "translateX(0%)";
  }};
`;

export const FloatingIconButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  background-color: white;

  svg {
    height: 22px;
    width: 22px;
  }

  &:hover {
    background-color: #eeeeee;
    color: ${(props) => props.theme.palette.info.light};
  }
`;
