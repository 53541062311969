/* eslint-disable */
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import PrivateHeader from "../../../components/PrivateHeader";
import VendorReports from "./vendorReports";
import RetailReports from "./retailReports";
import { GlobalState } from "../../../store/GlobalState";
import OverallReport from "./retailReports/overallReport";
import { BarChart } from "react-feather";

const Reports = () => {
  const { state } = useContext(GlobalState);
  const { isRetail, job } = state;

  if (isRetail && job === "M") {
    return (
      <>
        <PrivateHeader header="Reports" />
        <Helmet title="Spiffy Reports" />
        <OverallReport isManager />
      </>
    );
  }

  if (isRetail) {
    return (
      <>
        <PrivateHeader header="Reports" Icon={BarChart} />
        <Helmet title="Spiffy Reports" />
        <RetailReports />
      </>
    );
  }

  return (
    <>
      <PrivateHeader header="Reports" Icon={BarChart} />
      <Helmet title="Spiffy Reports" />
      <VendorReports />
    </>
  );
};
export default Reports;
