/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import styled from "styled-components/macro";
import Top10Chart from "./Top10Chart";
import SubmissionTable from "./SubmissionTable";
import HomeEngagementChart from "../reuseableComponents/HomeEngagementChart";
import BreakdownTable from "./BreakdownTable";
import VendorHomeHero from "./VendorHomeHero";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import OnLoadError from "../../../../components/UI/OnLoadError";
import useHome from "../../../../swr/hooks/Home/useHome";
import PrivateHeader from "../../../../components/PrivateHeader";
// import SubmissionResultIndicator from "../../../../components/SubmissionResultIndicator";
import NotApprovedAlert from "../reuseableComponents/NotApprovedAlert";
import EngagementTypeSelect from "../../reports/reuseableComponents/EngagementTypeSelect";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { getMostRecentSub } from "../utils";
import { Book, Briefcase } from "react-feather";

const Spacer = styled.div`
  height: 16px;
`;

const ChartsWrapper = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const getFilteredSubmissions = (submissions, engagementType) => {
  if (engagementType === ENGAGEMENT_TYPES.ALL) return submissions;

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED)
    return submissions.filter((sub) => !sub.is_follower_submission);

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT)
    return submissions.filter((sub) => sub.is_follower_submission);
};

const VendorHome = ({ isApproved }) => {
  const { isLoading, data, isError } = useHome();
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  if (isLoading) {
    return (
      <>
        <LoaderWrapper text="Home" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  const filteredSubmissions = getFilteredSubmissions(
    data.recent_submissions,
    engagementType
  );

  const lastSubmission = getMostRecentSub(
    filteredSubmissions,
    data?.users_info
  );

  return (
    <>
      <PrivateHeader header="Home">
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          isVendor
        />
      </PrivateHeader>
      {!isApproved && (
        <>
          <NotApprovedAlert />
          <div style={{ height: 16 }} />
        </>
      )}

      <div style={{ height: 16 }} />
      <VendorHomeHero
        data={data}
        engagementType={engagementType}
        lastSubmission={lastSubmission}
      />
      <Spacer />

      <HomeEngagementChart
        submissions={filteredSubmissions}
        isVendor
        engagementType={engagementType}
      />

      <Spacer />
      <ChartsWrapper>
        <Top10Chart
          title="Most Popular Modules"
          Icon={Book}
          companies={data.activeCompanies}
          allSubmissions={filteredSubmissions}
          metric="MODULE"
          users={data.users_info}
          engagementType={engagementType}
        />
        <Top10Chart
          title="Most Engaged Companies"
          Icon={Briefcase}
          companies={data.activeCompanies}
          allSubmissions={filteredSubmissions}
          metric="COMPANY"
          users={data.users_info}
          engagementType={engagementType}
        />
      </ChartsWrapper>
      <Spacer />

      <Grid container spacing={4} justifyContent="flex-start">
        <Grid item xs={12}>
          <SubmissionTable
            submissions={filteredSubmissions}
            usersInfo={data.users_info}
            engagementType={engagementType}
          />
          <div style={{ height: 12 }} />
          <BreakdownTable
            submissions={filteredSubmissions}
            users={data.users_info}
            engagementType={engagementType}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default VendorHome;

VendorHome.propTypes = {
  data: PropTypes.object.isRequired,
};

// {lastSubmission && (
//   <Grid item xs={12}>
//     <div
//       style={{
//         width: "fit-content",
//         // height: 76,
//         padding: 10,
//         background: "#eeeeee",
//         borderRadius: 4,
//         minWidth: 300,
//       }}
//     >
//       <div
//         style={{
//           width: "100%",
//           height: "100%",
//           background: "white",
//           borderRadius: 4,
//           display: "flex",
//           alignItems: "flex-start",
//           justifyContent: "flex-start",
//           flexDirection: "column",
//           padding: "12px 16px",
//           gap: 2,
//         }}
//       >
//         {/* <Download height={14} width={14} color="black" /> */}
//         <p style={{ fontWeight: "600", color: "darkgrey" }}>
//           Most Recent Submission
//         </p>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "flex-start",
//             justifyContent: "flex-start",
//             gap: 6,
//           }}
//         >
//           <p style={{ fontWeight: "600" }}>
//             {lastSubmission?.quiz_name}
//           </p>
//           <p style={{ fontWeight: "500", color: "darkgrey" }}>|</p>
//           <p style={{ fontWeight: "600" }}>
//             {lastSubmission.users_first_name}{" "}
//             {lastSubmission.users_last_name}
//           </p>
//           {/* <p style={{ fontWeight: "500", color: "darkgrey" }}>|</p> */}

//           <div style={{ width: 12 }} />
//           <SubmissionResultIndicator
//             value={lastSubmission.passed ? "Yes" : "No"}
//           />
//         </div>
//         <p
//           style={{ fontWeight: "500", fontSize: 12, color: "darkgrey" }}
//         >
//           {new Date(lastSubmission.created).toDateString()}
//         </p>
//       </div>
//     </div>
//   </Grid>
// )}
