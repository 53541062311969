/* eslint-disable */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import React, { useContext, useState } from "react";
import { Box, Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import { mutate as swrMutate } from "swr";
import Vimeo from "vimeo";
import API from "../../../../axios/instances/API";
import { GlobalState } from "../../../../store/GlobalState";
import ACTIONS from "../../../../store/actions";
import useQuizMedia from "../../../../swr/hooks/Library/useQuizMedia";
import HalfPanel from "../UI/HalfPanel";
import RequiredChip from "../UI/RequiredChip";
import { LoaderCentered } from "../styles";
import ContentTypeRadio from "./ContentTypeRadio";
import CurrentMedia from "./CurrentMedia";
import DropzonePDF from "./DropzonePDF";
import DropzoneVideo from "./DropzoneVideo";
import Guidelines from "./Guidelines";
import NoPermissionsModal from "./NoPermissionsModal";

const handleVideoUploadError = (error) => {
  const response = error?.response;

  if (Boolean(response?.data?.error?.title)) {
    return {
      title: response?.data?.error?.title || "Error uploading video",
      detail: response?.data?.error?.details || "Please try again",
    };
  }

  return {
    title: "Error uploading video",
    detail: "Please try again",
  };
};

const handlePDFUploadError = (error) => {
  const response = error?.response;

  if (Boolean(response?.data?.error?.title)) {
    return {
      title: response?.data?.error?.title || "Error uploading PDF",
      detail: response?.data?.error?.details || "Please try again",
    };
  }

  return {
    title: "Error uploading PDF",
    detail: "Please try again",
  };
};

const Content = ({ id, setSnackbarText, details, isModuleLive }) => {
  const { mutate, media, isLoading } = useQuizMedia(id);
  const { state, dispatch } = useContext(GlobalState);
  const { transcoding } = state;

  const transcodingIds = transcoding.map((code) => code.quiz_id);

  const videoTranscoding =
    transcoding.length > 0 && transcodingIds.includes(Number(id));

  const [uploadOption, setUploadOption] = useState(
    videoTranscoding ? "V" : "P"
  );
  const [pdfAlert, setPdfAlert] = useState("");
  const [videoAlert, setVideoAlert] = useState("");
  const [uploadPDF, setUploadPDF] = useState([]);
  const [pdfUploading, setPdfUploading] = useState(false);
  const [uploadVideo, setUploadVideo] = useState([]);
  const [videoUploading, setVideoUploading] = useState(false);
  const [dropzoneKey, setDropZoneKey] = useState(false);
  const [displayPermissionsError, setDisplayPermissionError] = useState(null);

  const usersCompany = state?.config?.current_company?.name || "";

  const inDevelopment = details.state === "In Development";

  const VimeoAPI = Vimeo.Vimeo;
  const Client = new VimeoAPI(
    process.env.REACT_APP_CLIENT_IDENTIFIER,
    process.env.REACT_APP_CLIENT_SECRETS,
    process.env.REACT_APP_VIMEO_ACCESS_TOKEN
  );

  if (isLoading) {
    return (
      <LoaderCentered>
        <span style={{ color: "darkgrey" }}>loading...</span>
      </LoaderCentered>
    );
  }

  const handleRadioChange = (_, value) => {
    setUploadOption(value);
  };

  const handlePdfUpload = async () => {
    setPdfUploading(true);
    setDropZoneKey((p) => !p);
    const formData = new FormData();
    const [sendPdf] = uploadPDF;
    formData.append("content", sendPdf);
    formData.append("media_type", "P");
    formData.append("id", id);

    try {
      await API.put("dashboard/quiz/media/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      await mutate();
      setUploadPDF([]);
      setPdfUploading(false);
      setSnackbarText(`Successfully uploaded Pdf "${sendPdf.name}" success`);
    } catch (error) {
      setPdfUploading(false);
      const errorObj = handlePDFUploadError(error);
      setDisplayPermissionError(errorObj);
      setUploadPDF([]);
    }
  };

  const handleVideoUpload = async () => {
    const VIDEO_NAME = details.name;
    const VIDEO_NAME_NO_EMOJIS = VIDEO_NAME.replaceAll(/\p{Emoji}/gu, "-");
    const [videoFile] = uploadVideo;
    await completeUploadV2(VIDEO_NAME_NO_EMOJIS, videoFile.size, videoFile);
    setUploadVideo([]);
  };

  const uploadStepTwo = async (uploadLink, videoFile, uploadOffset) => {
    try {
      const res = await API.patch(uploadLink, videoFile, {
        headers: {
          "Tus-Resumable": "1.0.0",
          "Upload-Offset": `${uploadOffset}`,
          "Content-Type": "application/offset+octet-stream",
        },
      });

      return res?.headers["upload-offset"];
    } catch (error) {
      console.log(error);
    }
  };

  const completeUploadV2 = async (videoName = "none", size = 0, videoFile) => {
    const sendBodyV2 = {
      id,
      media_type: "V",
      video_name: videoName,
      video_size: size,
    };
    setVideoUploading(true);
    setDropZoneKey((p) => !p);

    try {
      const { data } = await API.put("/dashboard/quiz/media/", sendBodyV2);
      const uploadLink = data?.upload_link;

      let uploadOffset = 0;

      while (String(uploadOffset) !== String(size)) {
        const updatedUploadOffset = await uploadStepTwo(
          uploadLink,
          videoFile,
          uploadOffset
        );

        // console.log(
        //   "SIZE IS",
        //   size,
        //   "UPLOAD OFFSET UPDATED IS",
        //   updatedUploadOffset
        // );

        uploadOffset = updatedUploadOffset;
      }
      dispatch({
        type: ACTIONS.ADD_TRANSCODE,
        payload: {
          id,
          quiz_name: videoName,
          quiz_id: Number(id),
        },
      });
      await mutate();
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      setVideoUploading(false);
      setSnackbarText("Succesfully uploaded video success");
    } catch (error) {
      const errorObj = handleVideoUploadError(error);
      setDisplayPermissionError(errorObj);
      setVideoUploading(false);
    }
  };

  const handleRemovePdf = async () => {
    try {
      await API.delete("dashboard/quiz/media/", {
        data: {
          id,
          media_type: "P",
        },
      });
      await mutate();
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      return setSnackbarText("Removed pdf from module success");
    } catch (error) {
      return setSnackbarText("Failed to remove the pdf error");
    }
  };

  const handleRemoveVideo = async () => {
    try {
      await API.delete("dashboard/quiz/media/", {
        data: {
          id,
          media_type: "V",
        },
      });
      await mutate();
      await swrMutate(`/dashboard/quiz/review/?id=${id}`);
      return setSnackbarText("Removed the video for this module success");
    } catch (error) {
      return setSnackbarText("Failed to remove your video error");
    }
  };

  return (
    <>
      <HalfPanel
        title="Upload Content"
        RightComponent={() => (
          <RequiredChip
            completed={Boolean(details?.media || details?.video)}
            title="Required"
            small
            requirementText="Every module must have at least 1 piece of content"
          />
        )}
      >
        <Box mb={4}>
          <ContentTypeRadio
            uploadOption={uploadOption}
            handleRadioChange={handleRadioChange}
          />
        </Box>
        {uploadOption === "P" && (
          <DropzonePDF
            setPdfAlert={setPdfAlert}
            setUploadPDF={setUploadPDF}
            pdfAlert={pdfAlert}
            handlePdfUpload={handlePdfUpload}
            uploadPDF={uploadPDF}
            dropzoneKey={dropzoneKey}
            pdfUploading={pdfUploading}
            setSnackbarText={setSnackbarText}
          />
        )}
        {uploadOption === "V" && (
          <DropzoneVideo
            setVideoAlert={setVideoAlert}
            setUploadVideo={setUploadVideo}
            videoAlert={videoAlert}
            uploadVideo={uploadVideo}
            videoUploading={videoUploading}
            handleVideoUpload={handleVideoUpload}
            videoTranscoding={videoTranscoding}
            dropzoneKey={dropzoneKey}
            setSnackbarText={setSnackbarText}
          />
        )}
        <div>
          <Guidelines uploadOption={uploadOption} />
        </div>
      </HalfPanel>
      <div
        style={{
          flex: 1,
          width: 1,
          maxWidth: 1,
          background: "#eeeeee",
          height: "inherit",
        }}
      />
      <HalfPanel title="Review Content">
        <CurrentMedia
          media={media}
          videoUploading={videoUploading}
          videoTranscoding={videoTranscoding}
          handleRemovePdf={handleRemovePdf}
          handleRemoveVideo={handleRemoveVideo}
          inDevelopment={inDevelopment}
          isModuleLive={isModuleLive}
          pdfUploading={pdfUploading}
        />
      </HalfPanel>
      <Modal
        open={Boolean(displayPermissionsError)}
        onClose={() => setDisplayPermissionError(null)}
      >
        <div>
          <NoPermissionsModal
            displayPermissionsError={displayPermissionsError}
            handleClose={() => setDisplayPermissionError(null)}
          />
        </div>
      </Modal>
    </>
  );
};

export default Content;

Content.propTypes = {
  id: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
};
