/* eslint-disable */
/* eslint-disable camelcase */

import React from "react";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import useCompanyReport from "../../../../../swr/hooks/VendorReports/useCompanyReport";
import Loader from "../Loader";
import CompaniesTable from "./CompaniesTable";

const CompanyBreakdown = () => {
  const { data, isLoading, isError } = useCompanyReport();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) return <ErrorBlockWithPermissions errorObj={isError} />;

  const {
    companies_breakdown: companies,
    users_with_team_members_in_multiple_companies: usersInMultiple,
  } = data;

  return (
    <>
      <CompaniesTable companies={companies} usersInMultiple={usersInMultiple} />
    </>
  );
};

export default CompanyBreakdown;
