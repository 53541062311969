/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";
import { getClientTimezone } from "../../../storage/helpers";

const { timezone } = getClientTimezone();

const fetcher = async (url, filterParams = null) => {
  const fullUrl = filterParams
    ? `${url}timezone=${timezone}${filterParams}`
    : `${url}timezone=${timezone}`;
  const { data } = await API.get(fullUrl);
  return data.locations;
};

// UPDATED

const URL = "/dashboard/analytics/retail-reports/location-list/?";

const useRetailReportsLocationList = (queryParams = null) => {
  const { data, error, mutate, isValidating } = useSWR(
    [URL, queryParams],
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    locations: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate,
  };
};

export default useRetailReportsLocationList;
