/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import useVendorUsersBreakdown from "../../../../../swr/hooks/VendorReports/useVendorUsersBreakdown";
import UsersTable from "../../../engagedUsers/UsersTable";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import Loader from "../Loader";

const TableWrapper = styled.div`
  min-width: 420px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const UsersBreakdown = () => {
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  const { isLoading, data, isError } = useVendorUsersBreakdown();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) return <ErrorBlockWithPermissions errorObj={isError} />;

  return (
    <TableWrapper>
      <EngagementTypeSelect
        engagementType={engagementType}
        setEngagementType={setEngagementType}
        isVendor
      />
      <div style={{ height: 12 }} />
      <UsersTable data={data.users_breakdown} engagementType={engagementType} />
    </TableWrapper>
  );
};

export default UsersBreakdown;
