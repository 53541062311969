/* eslint-disable */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Book } from "react-feather";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components/macro";
import NoImage from "../../../../../assets/images/empty-small.jpg";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../../components/Table/TableTitle";
import {
  DEFINITIONS,
  ENGAGEMENT_TYPES,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import {
  getSrcTwo,
  makeDefaultTableOptions,
} from "../../../../../storage/helpers";
import SubmissionTypeChip from "../../../home/retail/SubmissionTypeChip";
import {
  Button,
  CenterWithColor,
  CustomDatatableV2,
  ShowState,
  StyledLink,
} from "../../../styles";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import ShowModuleFeedback from "../../reuseableComponents/ShowModuleFeedback";
import { useMediaQuery } from "@material-ui/core";
import DateTooltip from "../../../../../components/Table/DateTooltip";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: 8px;
    width: ${(props) => (props.isWide === "yes" ? 50 : 40)}px;
    height: ${(props) => (props.isWide === "yes" ? 35 : 40)}px;
    height: 40px;
    width: 40px;
    border: 1px solid #eeeeee;
    object-fit: contain;
    border-radius: 4px;
  }
`;

const TableWrapper = styled.div`
  min-width: 420px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const formatState = (state) => {
  if (state !== "4") return "Live";

  return "Closed";
};

const options = makeDefaultTableOptions("modules");
options.rowsPerPage = 50;
options.sortOrder = {
  name: "comps",
  direction: "desc",
};
options.textLabels = {
  body: {
    noMatch:
      "You haven’t deployed any modules! Create new modules and deploy it to the marketplace to generate module reports!",
  },
};

const getTableData = (modules, engagementType, showClosed) => {
  let returnArr = [];

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    returnArr = modules.map((mod) => {
      return {
        ...mod,
        comps: mod.assigned_completions + mod.follower_completions,
        subs: mod.assigned_submissions + mod.follower_submissions,
        passRate:
          mod.assigned_submissions + mod.follower_submissions < 1
            ? 0
            : Math.floor(
                ((mod.assigned_completions + mod.follower_completions) /
                  (mod.assigned_submissions + mod.follower_submissions)) *
                  100
              ),
        users: mod.overall_unique_users,
        feedbackDifferential: mod.positive_feedback - mod.negative_feedback,
        feedback: {
          positive: mod.positive_feedback,
          negative: mod.negative_feedback,
        },
        state: formatState(mod.state),
      };
    });
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    returnArr = modules.map((mod) => {
      return {
        ...mod,
        comps: mod.assigned_completions,
        subs: mod.assigned_submissions,
        users: mod.assigned_unique_users,
        passRate:
          mod.assigned_submissions < 1
            ? 0
            : Math.floor(
                (mod.assigned_completions / mod.assigned_submissions) * 100
              ),
        feedbackDifferential: mod.positive_feedback - mod.negative_feedback,
        feedback: {
          positive: mod.positive_feedback,
          negative: mod.negative_feedback,
        },
        state: formatState(mod.state),
      };
    });
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    returnArr = modules.map((mod) => {
      return {
        ...mod,
        comps: mod.follower_completions,
        subs: mod.follower_submissions,
        users: mod.follower_unique_users,
        passRate:
          mod.follower_submissions < 1
            ? 0
            : Math.floor(
                (mod.follower_completions / mod.follower_submissions) * 100
              ),
        feedbackDifferential: mod.positive_feedback - mod.negative_feedback,
        feedback: {
          positive: mod.positive_feedback,
          negative: mod.negative_feedback,
        },
        state: formatState(mod.state),
      };
    });
  }

  return returnArr.filter((mod) => {
    if (showClosed) {
      return Boolean;
    }
    return mod.state === "Live";
  });
};

const QuizzesTable = ({ quizzes }) => {
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const [showClosed, setShowClosed] = useState(false);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("modules");
  options.rowsPerPage = 50;
  options.sortOrder = {
    name: "comps",
    direction: "desc",
  };
  options.textLabels = {
    body: {
      noMatch:
        "You haven’t deployed any modules! Create new modules and deploy it to the marketplace to generate module reports!",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <FlexDiv>
            <LazyLoadImage
              src={getSrcTwo(tableMeta.rowData[9])}
              effect="opacity"
              onError={(event) => (event.target.src = NoImage)}
            />
            <StyledLink
              to={`/modules/progress/${tableMeta.rowData[0]}`}
              value={value}
              CustomIcon={Book}
            />
          </FlexDiv>
        ),
      },
    },
    {
      name: "users",
      label: "Unique Users",
      options: {
        filter: true,
        sort: true,
        hint: DEFINITIONS.uniqueUsers,
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "subs",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("submissions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "comps",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("completions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{`${value}%`}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "feedbackDifferential",
      label: "Feedback",
      options: {
        hint: "Feedback left by users in the app",
        print: false,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <ShowModuleFeedback feedback={tableMeta.rowData[7]} />
        ),
      },
    },
    {
      name: "feedback",
      label: "",
      options: {
        print: false,
        download: false,
        display: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <ShowState state={value}>
              <span>{value}</span>
            </ShowState>
          );
        },
      },
    },
    {
      name: "image",
      label: "image",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
      },
    },
  ];

  const tableData = getTableData(quizzes, engagementType, showClosed);

  return (
    <TableWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 8,
        }}
      >
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          isVendor
        />
        <Button onClick={() => setShowClosed((p) => !p)} color="primary">
          {showClosed ? "Hide Closed Modules" : "Show Closed Modules"}
        </Button>
      </div>
      <CustomDatatableV2
        title={
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 6,
            }}
          >
            <TableTitle
              title="Modules"
              Icon={Book}
              submissionType={engagementType}
              isVendor
            />
          </div>
        }
        columns={columns}
        data={tableData}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </TableWrapper>
  );
};

export default QuizzesTable;

QuizzesTable.propTypes = {
  quizzes: PropTypes.array.isRequired,
};
