/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";
import { getClientTimezone } from "../../../storage/helpers";

const { timezone } = getClientTimezone();

const fetcher = async (url, filterParams = null) => {
  const fullUrl = filterParams
    ? `${url}timezone=${timezone}${filterParams}`
    : `${url}timezone=${timezone}`;
  const { data } = await API.get(fullUrl);
  return data.team_members;
};

/// UPDATED

const BASE_URL = "/dashboard/analytics/retail-reports/team-member-list/?";

const useRetailReportsTeamMemberList = (queryParams = null) => {
  const { data, error, mutate, isValidating } = useSWR(
    [BASE_URL, queryParams],
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    teamMembers: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate,
  };
};

export default useRetailReportsTeamMemberList;
