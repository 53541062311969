/* eslint-disable */
import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import { UploadCloud } from "react-feather";
import { Button } from "../../styles";
import { Collapse } from "@material-ui/core";
import TranscodingOverlay from "../UI/TranscodingOverlay";
import UploadingOverlay from "../UI/UploadingOverlay";

const MAXFILESIZE = 800000000; // 800 mb

const DropzoneVideo = ({
  setVideoAlert,
  setUploadVideo,
  videoAlert,
  uploadVideo,
  videoUploading,
  handleVideoUpload,
  videoTranscoding,
  dropzoneKey,
  setSnackbarText,
}) => {
  const videoTooLarge = (file) => {
    const uploadSize = file[0].size;
    if (uploadSize > MAXFILESIZE) {
      return setSnackbarText(
        "Failed to receive video. Your video is over the max file size of 500mb error"
      );
    }
    return setSnackbarText("Failed to receive video. Please try again error");
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <DropzoneArea
          acceptedFiles={["video/*"]}
          showFileNamesInPreview
          showFileNames
          disabled={videoTranscoding || videoUploading}
          getFileAddedMessage={(fileName) => setVideoAlert(fileName)}
          filesLimit={1}
          dropzoneProps={{ disabled: videoUploading || videoTranscoding }}
          maxFileSize={MAXFILESIZE}
          onDropRejected={(file) => videoTooLarge(file)}
          showAlerts={false}
          onChange={(file) => {
            if ((videoTranscoding || videoUploading) && file.length > 0) {
              return setSnackbarText(
                "Cannot add new video while one is trascoding/uploading warning"
              );
            }
            return setUploadVideo(file);
          }}
          dropzoneText={
            <div
              style={{
                height: 80,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ fontSize: 16 }}>Drop a video here</span>
              <span style={{ fontSize: 13, color: "darkgrey" }}>
                {videoTranscoding
                  ? "Video transcoding"
                  : videoUploading
                  ? "Uploading"
                  : "Accepts video files"}
              </span>
            </div>
          }
          key={dropzoneKey}
        />
        {videoTranscoding && <TranscodingOverlay />}
        {videoUploading && <UploadingOverlay />}
      </div>
      <Collapse
        in={videoAlert && uploadVideo.length === 1 && !videoUploading}
        style={{ width: "100%" }}
      >
        <Button
          mt={2}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleVideoUpload}
          startIcon={<UploadCloud color="white" height={16} width={16} />}
        >
          Upload Video
        </Button>
      </Collapse>
    </>
  );
};

export default DropzoneVideo;

DropzoneVideo.propTypes = {
  setVideoAlert: PropTypes.func.isRequired,
  setUploadVideo: PropTypes.func.isRequired,
  videoAlert: PropTypes.string.isRequired,
  uploadVideo: PropTypes.array.isRequired,
  videoUploading: PropTypes.bool.isRequired,
  handleVideoUpload: PropTypes.func.isRequired,
  percentageUploaded: PropTypes.number.isRequired,
  videoTranscoding: PropTypes.bool.isRequired,
  dropzoneKey: PropTypes.bool.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
