/* eslint-disable */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import SeeSubmissionModal from "../../../../components/SeeSubmission/SeeSubmissionModal";
import ActionButtonForTables from "../../../../components/Table/ActionButtonForTables";
import { Search } from "react-feather";
import { CustomDatatableV2 } from "../../styles";
import SubmissionResultIndicator from "../../../../components/Table/SubmissionResultIndicator";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import TableTitle from "../../../../components/Table/TableTitle";
import { Download } from "react-feather";
import Last14Chip from "../../../../components/Table/Last14Chip";
import DateTooltip from "../../../../components/Table/DateTooltip";
import SubmissionTypeChip from "./SubmissionTypeChip";

const getTableData = (subs, over100Subs) => {
  if (!subs) return [];

  if (over100Subs) {
    const sorted = subs.sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    );
    const sliced = sorted.slice(0, 100);
    return sliced.map((submission) => ({
      id: submission.id,
      date: new Date(submission.created).toISOString(),
      teamMemberName: `${submission.first_name} ${submission.last_name}`,
      quiz: submission.quiz_name,
      type: submission.assigned ? "assigned" : "extra credit",
      location:
        submission?.active_team_members.length > 1
          ? "multiple locations"
          : submission.active_team_members?.[0]?.location_name || "none",
      score: `${submission.score}/${submission.questions}`,
      passed: submission.passed ? "Yes" : "No",
    }));
  }

  return subs.map((submission) => ({
    id: submission.id,
    date: new Date(submission.created).toISOString(),
    teamMemberName: `${submission.first_name} ${submission.last_name}`,
    quiz: submission.quiz_name,
    type: submission.assigned ? "assigned" : "extra credit",
    location:
      submission?.active_team_members.length > 1
        ? "multiple locations"
        : submission.active_team_members?.[0]?.location_name || "none",
    score: `${submission.score}/${submission.questions}`,
    passed: submission.passed ? "Yes" : "No",
  }));
};

const AllSubmissionsTable = ({ submissions, engagementType }) => {
  const [submissionInfo, setSubmissionInfo] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const over100Subs = submissions && submissions.length > 100;

  const options = makeDefaultTableOptions("submissions-last-14");

  options.sortOrder = {
    name: "date",
    direction: "desc",
  };
  options.rowsPerPage = 10;
  options.textLabels = {
    body: {
      noMatch: "No new submissions in the last 14 days",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <span style={{ fontSize: 12 }}>
            <DateTooltip date={value} shouldLocalize displayYear />
          </span>
        ),
      },
    },
    {
      name: "teamMemberName",
      label: "Member",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (["multiple locations", "none"].includes(value)) {
            return (
              <span style={{ fontSize: 11, color: "darkgrey" }}>*{value}</span>
            );
          }
          return <span>{value}</span>;
        },
      },
    },
    {
      name: "quiz",
      label: "Module",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <SubmissionTypeChip
            small
            submissionType={
              value === "assigned"
                ? ENGAGEMENT_TYPES.ASSIGNED
                : ENGAGEMENT_TYPES.EXTRACREDIT
            }
          />
        ),
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "passed",
      label: "Result",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            Icon={Search}
            title="view"
            handleClick={() => setSubmissionInfo({ id: tableMeta.rowData[0] })}
          />
        ),
      },
    },
  ];

  const tableData = useMemo(
    () => getTableData(submissions, over100Subs),
    [submissions]
  );

  return (
    <>
      <CustomDatatableV2
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 8,
            }}
          >
            <TableTitle
              Icon={Download}
              title="Recent Submissions"
              submissionType={engagementType}
            />
            <Last14Chip />
          </div>
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <SeeSubmissionModal
        submissionInfo={submissionInfo}
        setSubmissionInfo={setSubmissionInfo}
      />
    </>
  );
};

export default AllSubmissionsTable;

AllSubmissionsTable.propTypes = {
  submissions: PropTypes.array.isRequired,
};
