/* eslint-disable */
import React, { useState } from "react";
import { Paper, TextField, Button } from "@material-ui/core";
import styled from "styled-components/macro";
import API from "../../../../axios/instances/API";

const ModalWrapper = styled(Paper)`
  padding: 12px;
  width: 460px;
  max-height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const EditDisclaimerModal = ({
  details,
  setSnackbarText,
  handleClose,
  mutate,
  setFieldValue,
}) => {
  const [text, setText] = useState(details?.disclaimerText);

  const [isUpdating, setIsUpdating] = useState(false);

  const handleSave = async () => {
    try {
      setIsUpdating(true);
      const sendObj = {
        id: details?.id,
        name: details?.name,
        description: details?.description,
        estimated_time: details?.estimatedTime,
        language: details?.language,
        due_date: details?.dueDate,
        start_date: details?.startDate,
        display_answers: details?.displayAnswers,
        disclaimer: details?.disclaimer,
        disclaimer_text: text,
      };
      await API.put("dashboard/quiz/details/", sendObj);
      await mutate();
      setIsUpdating(false);
      setFieldValue("disclaimer", text);
      setSnackbarText("Module disclaimer updated success");
      handleClose();
    } catch (error) {
      setIsUpdating(false);
      setSnackbarText("Failed to update module name error");
    }
  };

  const textLength = text.trim().length;

  return (
    <ModalWrapper>
      <span style={{ fontSize: 15, fontWeight: "600" }}>
        Edit Module Disclaimer
      </span>
      <div style={{ height: 8 }} />
      <TextField
        multiline
        minRows={3}
        fullWidth
        variant="outlined"
        size="small"
        color="secondary"
        error={textLength < 3}
        onChange={(e) => setText(e.target.value)}
        value={text}
        inputProps={{
          maxLength: 300,
        }}
      />
      <div style={{ height: 8 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        {textLength < 3 ? (
          <span
            style={{
              paddingLeft: 8,
              color: "tomato",
              fontSize: 11,
              fontWeight: "500",
            }}
          >
            *3 characters minimum*
          </span>
        ) : (
          <div />
        )}
        <Button
          color="secondary"
          variant="contained"
          size="small"
          style={{ alignSelf: "flex-end" }}
          onClick={handleSave}
          disabled={
            textLength < 3 || text === details?.disclaimerText || isUpdating
          }
        >
          {isUpdating ? "..." : "Save"}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default EditDisclaimerModal;
