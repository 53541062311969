/* eslint-disable */
/* eslint-disable import/prefer-default-export */

export const getIncompleteErrMessage = (steps, isVendor = false) => {
  let missingSteps;
  if (!isVendor) {
    missingSteps = steps.filter(
      (step) => !step.complete && step.label !== "Launch"
    );
  } else {
    missingSteps = steps.filter(
      (step) => !step.complete && !["Launch", "Audience"].includes(step.label)
    );
  }

  if (missingSteps.length === 1) {
    return `Looks like your module is incomplete. Make sure you have "${missingSteps[0].label}" filled out correctly.`;
  }

  if (missingSteps.length === 2) {
    return `Looks like your module is incomplete. Make sure "${missingSteps[0].label}" and "${missingSteps[1].label}" are filled out correctly.`;
  }

  return "Looks like your module is incomplete, Make sure you have your basic information filled out, a content piece, an audience, and questions.";
};

export const getVendorAddToMarketplaceErrorMsg = (error) => {
  if (error?.response?.data?.error?.details) {
    return `${error?.response?.data?.error?.details} error`;
  }

  return "Failed To launch your module error";
};

export const getVendorSuggestErrorMsg = (error) => {
  if (error?.response?.data?.error?.details) {
    return `${error?.response?.data?.error?.details} error`;
  }

  if (error?.response?.data?.detail) {
    return `${error?.response?.data?.detail} error`;
  }

  return "Error suggesting this module error";
};
