/* eslint-disable */
import React, { useState } from "react";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import useRetailReportsLocationList from "../../../../../swr/hooks/RetailReports/useRetailReportsLocationList";
import DateRange from "../../filter/DateRange";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import Loader from "../../vendorReports/Loader";
import LocationsTable from "./LocationsTable";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";

const LocationsReport = () => {
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const [filterDateString, setFilterDateString] = useState(null);

  const { locations, isLoading, isError } = useRetailReportsLocationList(
    filterDateString?.queryString || null
  );

  if (isLoading) return <Loader />;

  if (isError) return <ErrorBlockWithPermissions errorObj={isError} />;

  return (
    <>
      <div style={{ height: 12 }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
        />
        <DateRange
          setEngagementType={setEngagementType}
          setFilterDateString={setFilterDateString}
        />
      </div>
      <div style={{ height: 12 }} />
      <LocationsTable
        filterDateString={filterDateString}
        locations={locations}
        engagementType={engagementType}
        setFilterDateString={setFilterDateString}
      />
    </>
  );
};

export default LocationsReport;
