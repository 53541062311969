/* eslint-disable */
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import PrivateHeader from "../../../components/PrivateHeader";
import LoaderWrapper from "../../../components//Loaders/LoaderWrapper";
import useFollowers from "../../../swr/hooks/Followers/useFollowers";
import useVendorUsersBreakdown from "../../../swr/hooks/VendorReports/useVendorUsersBreakdown";
import UsersTable from "./UsersTable";
import UsersBlocks from "./UsersBlocks";
import { ENGAGEMENT_TYPES } from "../../../storage/constants";
import EngagementTypeSelect from "../reports/reuseableComponents/EngagementTypeSelect";
import ServerSideSubmissionTable from "./ServerSideSubmissionTable";
import { UserPlus } from "react-feather";
import ErrorBlockWithPermissions from "../../../components/UI/ErrorBlockWithPermissions";

const EngagedUsers = () => {
  const { isLoading, isError, followers } = useFollowers();
  const { isLoading: isLoadingTwo, data } = useVendorUsersBreakdown();

  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  if (isLoading || isLoadingTwo) {
    return (
      <>
        <Helmet title="Spiffy | Engaged Users" />
        <PrivateHeader header="Engaged Users" Icon={UserPlus} />
        <LoaderWrapper text="Engaged Users" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <Helmet title="Spiffy | Engaged Users" />
        <PrivateHeader header="Engaged Users" Icon={UserPlus} />
        <ErrorBlockWithPermissions errorObj={isError} />
      </>
    );
  }

  return (
    <>
      <Helmet title="Spiffy | Engaged Users" />
      <PrivateHeader header="Engaged Users" Icon={UserPlus} />
      <EngagementTypeSelect
        engagementType={engagementType}
        setEngagementType={setEngagementType}
        isVendor
      />
      <div style={{ height: 12 }} />
      <UsersBlocks followersData={followers} engagementType={engagementType} />
      <UsersTable data={data.users_breakdown} engagementType={engagementType} />
      <ServerSideSubmissionTable
        engagementType={engagementType}
        users={data.users_breakdown}
      />
    </>
  );
};

export default EngagedUsers;
