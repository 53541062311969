/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Calendar, MapPin } from "react-feather";
import { useMediaQuery } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import styled from "styled-components/macro";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../../components/Table/TableTitle";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import useLocationProgress from "../../../../../swr/hooks/Locations/useLocationProgress";
import {
  CenterWithColor,
  CustomDatatableV2,
  StyledLink,
} from "../../../styles";
import OutstandingModules from "../../reuseableComponents/OutstandingModules";
import ProgressBar from "../../reuseableComponents/ProgressBar";
import { retOutstanding, retProgress } from "../../utils/utils";

const CustomChip = styled(Chip)`
  border-radius: 4px;
  margin-left: 12px;
  border: 1px solid lightgrey;
  background-color: aliceblue;
  height: 28px;
`;

const AlignLeftDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const getCompsByEngagementType = (location, engagementType) => {
  try {
    if (engagementType === ENGAGEMENT_TYPES.ALL) {
      return (
        location?.assigned_completions + location?.extra_credit_completions
      );
    }

    if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
      return location.assigned_completions;
    }

    return location.extra_credit_completions;
  } catch (error) {
    return 0;
  }
};

const getPassRateByEngagementType = (location, engagementType) => {
  let totalComps;
  let totalSubs;

  if (engagementType === ENGAGEMENT_TYPES.ALL) {
    totalComps =
      location.assigned_completions + location.extra_credit_completions;
    totalSubs =
      location.assigned_submissions + location.extra_credit_submissions;
  }

  if (engagementType === ENGAGEMENT_TYPES.ASSIGNED) {
    totalComps = location.assigned_completions;
    totalSubs = location.assigned_submissions;
  }

  if (engagementType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    totalComps = location.extra_credit_completions;
    totalSubs = location.extra_credit_submissions;
  }
  if (totalSubs === 0) return 0;
  const res = Math.floor((totalComps / totalSubs) * 100);

  return res;
};

const LocationsTable = ({
  locations,
  engagementType,
  filterDateString,
  setFilterDateString,
}) => {
  const { progress, isLoading, isError } = useLocationProgress();
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const tableData = locations.map((location) => ({
    id: location?.id,
    name: location?.name,
    address: `${location?.line1}, ${location?.city_name}, ${location?.country_name}`,
    completions: getCompsByEngagementType(location, engagementType),
    passRate: getPassRateByEngagementType(location, engagementType),
    numTeamMembers: location?.team_members,
    outstanding: retOutstanding(location?.id, progress),
    progress: retProgress(location?.id, progress),
    spiffyScore: Math.floor(location?.spiffy_score),
  }));

  const options = makeDefaultTableOptions("location-report");
  options.rowsPerPage = 50;
  options.downloadOptions.filterOptions.useDisplayedColumnsOnly = false;
  options.textLabels = {
    body: {
      noMatch:
        "No locations created for your team yet. Create a new location to populate this list.",
    },
  };
  options.sortOrder = {
    name: "spiffyScore",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        print: false,
      },
    },
    {
      name: "name",
      label: "Location",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/locations/detail/${tableMeta.rowData[0]}`}
            value={value}
            CustomIcon={MapPin}
          />
        ),
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "numTeamMembers",
      label: "Team Members",
      options: {
        filter: false,
        sort: false,
        display: true,
        print: true,
        download: true,
        customBodyRender: (val) => {
          if (filterDateString) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }
          return <span>{val}</span>;
        },
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        download: true,
        customBodyRender: (value) => {
          return (
            <CenterWithColor engagementType={engagementType}>
              <span>{value}</span>
            </CenterWithColor>
          );
        },
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        filter: true,
        sort: true,
        download: true,
        customBodyRender: (value) => {
          return (
            <CenterWithColor engagementType={engagementType}>
              <span>{value}%</span>
            </CenterWithColor>
          );
        },
      },
    },

    {
      name: "outstanding",
      label: "Outstanding Modules",
      options: {
        filter: true,
        sort: true,
        download: true,
        display: false,
        customBodyRender: (_, tableMeta) => {
          if (
            engagementType === ENGAGEMENT_TYPES.EXTRACREDIT ||
            filterDateString
          ) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }

          return (
            <OutstandingModules
              progress={progress}
              id={tableMeta.rowData[0]}
              isLoading={isLoading}
              isError={isError}
            />
          );
        },
      },
    },
    {
      name: "progress",
      label: "Progress",
      options: {
        filter: false,
        sort: true,
        download: true,
        customBodyRender: (_, tableMeta) => {
          if (
            engagementType === ENGAGEMENT_TYPES.EXTRACREDIT ||
            filterDateString
          ) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }

          return (
            <AlignLeftDiv>
              <ProgressBar
                progress={progress}
                id={tableMeta.rowData[0]}
                isLoading={isLoading}
                isError={isError}
              />
            </AlignLeftDiv>
          );
        },
      },
    },
    {
      name: "spiffyScore",
      label: "Avg Spiffy Score",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          if (filterDateString) {
            return <span style={{ color: "darkgrey" }}>---/---</span>;
          }

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
              }}
            >
              <span style={{ fontWeight: "600" }}>{value}</span>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",

              gap: 6,
            }}
          >
            <TableTitle
              Icon={MapPin}
              title="Locations Report"
              submissionType={engagementType}
            />
            {filterDateString && (
              <CustomChip
                label={filterDateString.readString}
                clickable
                size="small"
                onDelete={() => setFilterDateString(null)}
                icon={
                  <Calendar style={{ height: 14, width: 14, color: "black" }} />
                }
              />
            )}
            {!filterDateString && engagementType === ENGAGEMENT_TYPES.ALL && (
              <span
                style={{ fontSize: 12, color: "lightgrey", marginLeft: 12 }}
              >
                No Filters Applied
              </span>
            )}
          </div>
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default LocationsTable;

LocationsTable.propTypes = {
  locations: PropTypes.array.isRequired,
};
